import Api from '../index';

export const ApiAdmin = {
    async getPendingDrivers() {
        return await Api.Route()
            .get(`admin/getPendingDrivers`)
            .then(res => res.data)
            .catch(e => e);
    },
    async getDriverById(id) {
        return await Api.Route()
            .get(`admin/getDriverById/${id}`)
            .then(res => res.data)
            .catch(e => e);
    },
    async putUpdateStatusDriver(driver) {
        return await Api.Route()
            .put(`admin/putUpdateStatusDriver`, driver)
            .then(res => res)
            .catch(e => e);
    },
    async getReport(ano, mes, tipoPeriodo){
        return await Api.Route()
            .get(`admin/getReport/${ano}/${mes}/${tipoPeriodo}`)
            .then(res => res)
            .catch(e => e);
    },
    async getAllDrivers(){
        return await Api.Route()
            .get(`admin/getAllDrivers`)
            .then(res => res.data)
            .catch(e => e);
    },
    async getAllCompanies(){
        return await Api.Route()
            .get(`admin/getAllCompanies`)
            .then(res => res.data)
            .catch(e => e);
    }
    
}

export default ApiAdmin;
