import React from "react";
import Header from "../../home/header";
import Footer from "../../home/footer";
import './styles.css';

const Storage = () => {
    return (
        <>
            <Header />
            <div className="content-container">

                <section className="description">
                    <h1 className="title">Armazenagem</h1>
                    <p>Na SameDay, oferecemos uma solução de armazenagem estratégica com centros de distribuição localizados próximos aos grandes centros urbanos, garantindo eficiência e rapidez na movimentação das mercadorias. Nossa inovação, as Stock Stores, transforma espaços urbanos ociosos em mini centros de distribuição, proporcionando uma armazenagem flexível e econômica, onde você paga apenas pelo espaço que realmente utiliza, conforme a demanda dos seus produtos. </p>
                </section>

                <section>
                    <h2>Como funciona?</h2>
                    <p>A armazenagem funciona com centros de distribuição e Stock Stores estrategicamente localizados dentro de  grandes centros urbanos. As mercadorias são recebidas, organizadas e armazenadas nesses espaços, com um sistema de gestão avançado que garante controle preciso do inventário. Isso permite flexibilidade no uso do espaço, onde os clientes pagam apenas pelo que realmente utilizam, otimizando custos e garantindo uma operação ágil e eficiente.</p>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Storage;