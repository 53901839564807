import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from '../home';

//Client
import ClientRegister from '../credentials/client-register';
import ClientLogin from '../credentials/client-login';
import ClientDashBoard from '../client/dashboard'
import NewOrder from '../client/new-order'
import Profile from '../client/profile'
import Orders from '../client/orders'
import ClientReports from "../client/reports";

//Driver
import DriverRegister from '../credentials/driver-register';
import Driver from '../driver';

//Admin
import AdminLogin from '../credentials/admin-login';
import ValidateDrivers from "../admin/validate-drivers";
import AdminDashboard from "../admin/dashboard";
import AdminReports from "../admin/reports";
import FractionalLogistcs from "../services/fractional-logistics";
import Storage from "../services/storage";
import Partners from "../services/partners";
import ShippingSimulator from "../services/shipping-simulator";

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/index.html" component={Home} />
            <Route exact path="/driver" component={Driver} />
            <Route exact path="/fractional-logistics" component={FractionalLogistcs} />
            <Route exact path="/storage" component={Storage} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/delivery-requests" component={ShippingSimulator} />
            <Route exact path="/shipping-simulator" component={ShippingSimulator} />

            {/*Admin*/}
            <Route path="/admin-login" component={AdminLogin} />
            <Route path="/admin-dashboard" component={AdminDashboard} />
            <Route path="/admin-validate-drivers" component={ValidateDrivers} />
            <Route path="/admin-reports" component={AdminReports} />

            {/*Client*/}
            <Route path="/client-register" component={ClientRegister} />
            <Route path="/client-login" component={ClientLogin} />
            <Route path='/client-dashboard' component={ClientDashBoard} />
            <Route path='/client-new-order' component={NewOrder} />
            <Route path='/client-profile' component={Profile} />
            <Route path='/client-reports' component={ClientReports} />
            <Route path='/client-orders' component={Orders} />

            <Route path="/driver-register" component={DriverRegister} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
