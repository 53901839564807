import React  from "react";
import './index.css';
import { withRouter } from "react-router-dom/cjs/react-router-dom";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    const partialUrl = window.location.href.split('/');

    this.state = {
      isHome: partialUrl[partialUrl.length - 1] === '',
    }
  }

  goTo(endpoint) {
    window.location.href = `https://sameday-web.herokuapp.com/${endpoint}`;
  }

  render() {
    const { isHome } = this.state;

    return (
      <React.Fragment>
        <header className="header" id="header">
          <nav className={`navbar navbar-expand-lg fixed-top bg-transparent ${this.props.isTransparent ? 'bg-transparent' : 'bg-color'}`}>
            <div className="container">
              <a className="navbar-brand" href="/">
                <img
                  src={"image/Same_Day_Logo3.png"}
                  width="150"
                  height="70"
                  alt="logo"
                  className="img-fluid"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu"></span>
              </button>

              <div
                className="collapse navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={isHome ? "#header" : '/'}>
                      Início
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={isHome ? "#about" : '/'}>
                      Sobre
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={isHome ? "#pricing" : "/"}>
                      Detalhes
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link page-scroll" href={isHome ? "#contact" : "/"}>
                      Contato
                    </a>
                  </li>
                  <li className="nav-item btn-login-right main-dropdown">
                    <a className="nav-link page-scroll" href="#">
                      Serviços
                      <i className="ti-angle-down"></i>
                    </a>

                    <div className="dropdown-wrapper">
                      <div className="dropdown-container">
                        <a className="dropdown-item" href="fractional-logistics">Logística Fracionada</a>
                        <a className="dropdown-item" href="storage">Armazenagem</a>
                        <a className="dropdown-item" href="partners">Parceiros</a>
                        <a className="dropdown-item" href="delivery-requests">Solicitações de Entregas</a>
                        <a className="dropdown-item" href="shipping-simulator">Simulador de Frete</a>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <button onClick={() => this.goTo('login')} className="btn btn-success btn-login" type="button">
                      Área Restrita
                    </button>
                  </li>
                  <li className="nav-item dropdown">
                    <button onClick={() => this.goTo('register')} className="btn btn-success btn-login" type="button" >
                      Cadastre-se
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}