import React, { useState } from "react";
import Header from "../../home/header";
import Footer from "../../home/footer";
import './styles.css';

const drives = [
    {
        nomeCompleto: 'Ana Paula Oliveira',
        cpf: '123.456.789-00',
        modeloCaminhao: 'Volvo FH 540',
        placa: 'ABC1D23',
        cor: 'Azul Metálico',
        foto: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlc3NvYSUyMHNvcnJpZGVudGV8ZW58MHx8MHx8fDA%3D'
      },
      {
        nomeCompleto: 'Roberto Silva Santos',
        cpf: '987.654.321-99',
        modeloCaminhao: 'Mercedes-Benz Actros 2646',
        placa: 'XYZ4E56',
        cor: 'Preto',
        foto: 'https://images.unsplash.com/photo-1506277886164-e25aa3f4ef7f?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
      {
        nomeCompleto: 'Carlos Eduardo Lima',
        cpf: '456.789.123-11',
        modeloCaminhao: 'Scania R 450',
        placa: 'MNO7P89',
        cor: 'Branco',
        foto: 'https://plus.unsplash.com/premium_photo-1673984261102-7e44ace8745a?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      }
];

const ShippingSimulator = () => {
    const [zipCodeOrigin, setZipCodeOrigin] = useState('');
    const [zipCodeDestination, setZipCodeDestination] = useState('');
    const [addressOrigin, setAddressOrigin] = useState('');
    const [responsibleNameOrigin, setResponsibleNameOrigin] = useState('');
    const [responsibleNameDestination, setResponsibleNameDestination] = useState('');
    const [cityOrigin, setCityOrigin] = useState('');
    const [stateOrigin, setStateOrigin] = useState('');
    const [addressDestination, setAddressDestination] = useState('');
    const [cityDestination, setCityDestination] = useState('');
    const [stateDestination, setStateDestination] = useState('');
    const [isCPF, setIsCPF] = useState(null);
    const [CPF ,setCPF] = useState('');
    const [CNPJ ,setCNPJ] = useState('');
    const [name ,setName] = useState('');
    const [companyName ,setCompanyName] = useState('');
    const [collectionDate ,setCollectionDate] = useState();
    const [phone ,setPhone] = useState('');
    const [email ,setEmail] = useState('');
    const [isWhatsapp, setIsWhatsapp] = useState(false);
    const [readyForPickup, setReadyForPickup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            setShowResult(true);
        }, 1200)
        
    }

    return (
        <>
            <Header />
            <div className="content-container">

                <section className="description">
                    <h1 className="title">Solicitação de Frete</h1>
                    <p>Preencha as informações abaixo e experimente a eficiência e confiabilidade da SameDay. Garantimos que sua mercadoria chegará ao destino com rapidez, segurança e total transparência. Simplifique seu processo de entrega com quem entende de logística.</p>
                </section>

                <section>
                    <form className="row needs-validation" onSubmit={handleSubmit}>
                        <div className="row col-12 mb-5">
                            <div className="col-12">
                                <h5>Dados da encomenda</h5>
                                <div className="justify-content-between">
                                    <div class="input-group mb-3 row col-sm-12 col-12">
                                        <input type="text" className="form-control" placeholder="Peso" aria-label="peso" aria-describedby="peso"/>
                                        <span className="input-group-text" id="basic-addon2">kg</span>
                                    </div>
                                    <div class="input-group mb-3 row col-sm-12 col-12">
                                        <input type="text" className="form-control" placeholder="Comprimento" aria-label="comprimento" aria-describedby="comprimento"/>
                                        <span className="input-group-text" id="basic-addon2">cm</span>
                                    </div>
                                    <div class="input-group mb-3 row col-sm-12 col-12">
                                        <input type="text" className="form-control" placeholder="Largura" aria-label="largura" aria-describedby="largura"/>
                                        <span className="input-group-text" id="basic-addon2">cm</span>
                                    </div>
                                    <div class="input-group mb-3 row col-sm-12 col-12">
                                        <input type="text" className="form-control" placeholder="Largura" aria-label="largura" aria-describedby="largura"/>
                                        <span className="input-group-text" id="basic-addon2">cm</span>
                                    </div>
                                    <div class="input-group mb-3 row col-sm-12 col-12">
                                        <span class="input-group-text">R$</span>
                                        <input type="text" className="form-control" placeholder="Valor da mercadoria" aria-label="value" aria-describedby="value"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-12 justify-content-between">
                            <div className="col">
                                <h5>Origem</h5>
                                <div className="col-sm-12 col-12">
                                    <div className="form-group">
                                        <div className="row mb-3">
                                            <input
                                                value={zipCodeOrigin}
                                                onChange={e => setZipCodeOrigin(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                name="zipCodeOrigin"
                                                placeholder="CEP"
                                                required
                                            />
                                        </div>
                                        <div className="row mb-3">
                                            <input
                                                value={addressOrigin}
                                                onChange={e => setAddressOrigin(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                name="addressOrigin"
                                                placeholder="Endereço"
                                                required
                                            />
                                        </div>
                                        <div className="row mb-3 justify-content-between">
                                            <input
                                                value={cityOrigin}
                                                onChange={e => setCityOrigin(e.target.value)}
                                                type="text"
                                                className="form-control col-6"
                                                name="cityOrigin"
                                                placeholder="Cidade"
                                                required
                                            />

                                            <input
                                                value={stateOrigin}
                                                onChange={e => setStateOrigin(e.target.value)}
                                                type="text"
                                                className="form-control col-5"
                                                name="stateOrigin"
                                                placeholder="Estado"
                                                required
                                            />
                                        </div>
                                        <div className="row mb-3 justify-content-between">
                                            <input
                                                value={responsibleNameOrigin}
                                                onChange={e => setResponsibleNameOrigin(e.target.value)}
                                                type="text"
                                                className="form-control col-12"
                                                name="responsibleNameOrigin"
                                                placeholder="Nome do responsável pela retirada"
                                                required
                                            />
                                        </div>
                                            <div className="row mb-3">
                                                <label className="mr-3">Produto pronto para retirada?</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="yes" id="yes" checked={readyForPickup} onChange={(e) => setReadyForPickup(e.target.checked)} />
                                                <label class="form-check-label" for="yes">Sim</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="no" id="no" checked={!readyForPickup} onChange={(e) => setReadyForPickup(!e.target.checked)} />
                                                <label class="form-check-label" for="no">Não</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <h5>Destino</h5>
                                <div className="col-sm-12 col-12">
                                    <div className="form-group">
                                        <div className="row mb-3">
                                            <input
                                                value={zipCodeDestination}
                                                onChange={e => setZipCodeDestination(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                name="zipCodeDestination"
                                                placeholder="CEP"
                                                required
                                            />
                                        </div>
                                        <div className="row mb-3">
                                            <input
                                                value={addressDestination}
                                                onChange={e => setAddressDestination(e.target.value)}
                                                type="text"
                                                className="form-control"
                                                name="addressDestination"
                                                placeholder="Endereço"
                                                required
                                            />
                                        </div>
                                        <div className="row mb-3 justify-content-between">
                                            <input
                                                value={cityDestination}
                                                onChange={e => setCityDestination(e.target.value)}
                                                type="text"
                                                className="form-control col-6"
                                                name="cityDestination"
                                                placeholder="Cidade"
                                                required
                                            />

                                            <input
                                                value={stateDestination}
                                                onChange={e => setStateDestination(e.target.value)}
                                                type="text"
                                                className="form-control col-5"
                                                name="stateDestination"
                                                placeholder="Estado"
                                                required
                                            />
                                        </div>
                                        <div className="row mb-3 justify-content-between">
                                            <input
                                                value={responsibleNameDestination}
                                                onChange={e => setResponsibleNameDestination(e.target.value)}
                                                type="text"
                                                className="form-control col-12"
                                                name="responsibleNameDestination"
                                                placeholder="Nome do responsável pelo recebimento"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn solid-btn mt-3" id="btnContactUs">Calcular frete</button>
                    </form>
                </section>

                

                <section >
                {loading && (
                    <div className="loading">
                        <div class="spinner-grow text-primary" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    </div>
                )}

                {showResult && (
                    <>
                        <h4>Resultado da simulação</h4>

                        <div className="drivers-container">
                            <h5>Opções de Transporte:</h5>

                            <div className="drivers-list">
                                {drives.map((driver) => (
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="driver-info">
                                                <h5 className="card-title">{driver.nomeCompleto}</h5>
                                                <span className="card-text">{driver.cpf}</span>
                                                <span className="card-text"><strong>Caminhão:</strong>{' ' + driver.modeloCaminhao}</span>
                                                <span className="card-text"><strong>Placa:</strong>{' ' + driver.placa}</span>
                                                <span className="card-text"><strong>Cor:</strong>{' ' + driver.cor}</span>
                                            </div>

                                            <div className="shipping-info">
                                                <span><strong>Cubagem disponível:</strong>{' 256'}</span>
                                                <span><strong>Tempo de Chegada para Retirada:</strong>{' 1 dia útil'}</span>
                                                <span><strong>Previsão de Saída para Destino:</strong>{' 24 horas'}</span>
                                                <span><strong>Previsão de Entrega Final:</strong>{' 12/10/2024'}</span>
                                                <span><strong>Preço do Frete:</strong>{' R$ 120,00'}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
                </section>

                
            </div>
            <Footer />

        </>
    )
}

export default ShippingSimulator;