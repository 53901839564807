import React from "react";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        promo: {
           items: [
              {
                title: "Entrega em até 24 horas ou seu dinheiro de volta",
                description:
                  "Nosso diferencial é a agilidade, sua entrega será realizada no mesmo dia, e isso nós garantimos",
                icon: "ti-vector text-white"
              },
              {
                title: "Uma frota robusta para atender qualquer negócio",
                description:
                  "Trabalhamos com os melhores motoristas com os mais diversos meios de transporte, garantido segurança e efetividade no trasporte",
                icon: "ti-lock text-white"
              },
              {
                title: "Inteligência a serviço da eficiência",
                description:
                  "Nosso sistema dispõe de uma inteligência artificial que calcula o melhor veículo da frota e mostra o trajeto em tempo real",
                icon: "ti-eye text-white"
              }
            ]
          },
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>Quer saber por que a Same Day é sua melhor opção?</h2>
                  <p className="lead">Garantimos a melhor e mais rápida entrega!</p>
                </div>
              </div>
            </div>
            <div className="row equal">
              {(this.state.promo.items || []).map(item => {
                return (
                  <div className="col-md-4 col-lg-4" key={item.title}>
                    <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                      <div className="mb-5">
                        <span className={item.icon}></span>
                      </div>
                      <h5>{item.title}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}