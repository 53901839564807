
export const ufs = [
    'UF', 'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF',
    'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA',
    'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS',
    'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
];

export const meses = [
    {
        id:'01',
        text:'Janeiro'
    },
    {
        id:'02',
        text:'Fevereiro'
    },
    {
        id:'03',
        text:'Março'
    },
    {
        id:'04',
        text:'Abril'
    },
    {
        id:'05',
        text:'Maio'
    },
    {
        id:'06',
        text:'Junho'
    },
    {
        id:'07',
        text:'Julho'
    },
    {
        id:'08',
        text:'Agosto'
    },
    {
        id:'09',
        text:'Setembro'
    },
    {
        id:'10',
        text:'Outubro'
    },
    {
        id:'11',
        text:'Novembro'
    },
    {
        id:'12',
        text:'Dezembro'
    },
];

export const anos = [
    '2021', '2020', '2019'
] 