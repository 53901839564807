import React from 'react';
import './index.css';
import { Link } from "react-router-dom";


export default class AdminDrawer extends React.Component {
    state = {
        open: false,
        nameRoute: '',
        dashRoute: '/admin-dashboard',
        validateRoute: '/admin-validate-drivers',
        reportsRoute: '/admin-reports',
    }

    componentDidMount = _ => this.setState({ nameRoute: window.location.pathname });


    openNav() {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    render() {
        const { open, page, nameRoute, dashRoute, validateRoute, reportsRoute } = this.state;
        const { content } = this.props;
        return (
            <>
                <div className="sidenav" style={{ width: open ? '250px' : '0px' }}>
                    <br />
                    <br />
                    <h5 style={{ color: '#EEE', marginLeft: '15px' }}>Same Day</h5>
                    <h5 style={{ color: '#EEE', marginLeft: '15px' }}>Painel do Admin</h5>
                    <hr />
                    <Link
                        to={{ pathname: '/admin-dashboard' }}
                        className={dashRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Dashboard
                    </Link>
                    <Link
                        to={{ pathname: '/admin-validate-drivers' }}
                        className={validateRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Validar Motoristas
                    </Link>
                    <Link
                        to={{ pathname: '/admin-reports' }}
                        className={reportsRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Relatórios
                    </Link>

                </div>
                <a href='#' className="btn-nav" style={{ marginLeft: open ? '190px' : '0px' }} onClick={() => this.openNav()}>
                    <i className="ti-menu"></i>
                </a>
                <div className='background-primary'>
                    {content}
                </div>
            </>
        );
    }
}