import React from 'react';
import './index.css';
import { storage } from '../../firebase';

import ApiMap from '../../api/map';
import ApiDriver from '../../api/driver';

import { ValidarNull, mCNPJCPF, mCEP, FormatPhoneMask, validarEmail, somenteNumeros } from '../../common/functions';
import { ufs } from '../../common/constants';
import Modal from '../../components/modal';
import { isUndefined } from 'lodash';


export default class DriverRegister extends React.Component {

    state = {
        razao: '',
        fantasia: '',
        cpfcnpj: '',
        endereco: '',
        numero: null,
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
        fotocomprovendereco: '',
        fotoantecedente: '',
        fone: '',
        email: '',
        site: '',

        fotomotorista: '',
        fotomotoristapreview: '',

        fotocnh: '',
        marca: '',
        modelo: '',
        ano: '',
        cor: '',
        placa: '',
        renavam: '',
        fotoveiculo: '',
        fotocrlv: '',
        capacidade: '',
        tipo: '',

        usuario: '',
        senha: '',
        confirmsenha: '',
        senhaok: false,

        forcasenha: '0%',
        barcolor: 'bg-danger',

        loading: false,

        modalshow: false,
        modalheader: '',
        modaltitle: '',
        modalmsg: '',
        modaltextbutton: '',
        actionButton: () => this.setState({ modalshow: false }),

        foto: ''
    }

    // => Senha
    async setSenha(senha) {
        await this.setState({ senha });
        await this.setConfirmSenha(this.state.confirmsenha);

        if (senha.length < 6) this.setState({ forcasenha: '0%', barcolor: 'bg-danger' });
        if (senha.length == 4) this.setState({ forcasenha: '7%', barcolor: 'bg-danger' });
        if (senha.length == 5) this.setState({ forcasenha: '10%', barcolor: 'bg-danger' });
        if (senha.length == 6) this.setState({ forcasenha: '15%', barcolor: 'bg-danger' });
        if (senha.length == 7) this.setState({ forcasenha: '25%', barcolor: 'bg-warning' });
        if (senha.length == 8) this.setState({ forcasenha: '40%', barcolor: 'bg-warning' });
        if (senha.length == 9) this.setState({ forcasenha: '50%', barcolor: 'bg-info' });
        if (senha.length == 10) this.setState({ forcasenha: '65%', barcolor: 'bg-info' });
        if (senha.length == 11) this.setState({ forcasenha: '80%', barcolor: 'bg-success' });
        if (senha.length == 12) this.setState({ forcasenha: '100%', barcolor: 'bg-success' });

    }

    setConfirmSenha(confirmsenha) {
        const { senha } = this.state;
        this.setState({ confirmsenha });

        if (confirmsenha.length > 0) {
            this.setState({ senhaok: senha == confirmsenha });
        } else {
            this.setState({ senhaok: false });
        }
    }
    // <= Senha

    // Cep
    handleCep = async _ => {

        const { cep } = this.state;
        
        if(!isUndefined(cep)){

            const local = await ApiMap.getLocation(cep.replace('.','').replace('-', ''));
            if (ValidarNull(local)) {
                this.setState({
                    cep: local.cep.replace('-', ''),
                    endereco: local.logradouro,
                    bairro: local.bairro,
                    cidade: local.localidade,
                    uf: local.uf,
                });
            }
            else {
                this.setState({
                    modalshow: true,
                    modalheader: 'Ops!',
                    modaltitle: 'CEP',
                    modalmsg:
                        'Não encontramos o CEP informado. Verifique e tente novamente. ',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });

                this.setState({
                    cep: '',
                    endereco: '',
                    bairro: '',
                    cidade: '',
                    uf: 'UF',
                });
            }
        }
        else{
            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'CEP',
                modalmsg:
                    'Não encontramos o CEP informado. Verifique e tente novamente. ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    // E-mail
    handleEmail(email){

        if(!validarEmail(email)){

            this.setState({
                email: ''
            });

            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'E-mail inválido',
                modalmsg:
                    'Digite um e-mail válido e tente novamente! ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    // => Carregamento de Img (Old)
    writeImage = async () => {
        await ApiDriver.postFotoDriver(this.state.foto);
    }

    loadImage = e => {
        //this.setState({ foto: e.target.files[0] });

        var reader = new FileReader();
        var file = e.target.files[0];

        reader.readAsDataURL(file);
        reader.onload = upload => {
            return upload.target.result;
            //this.setState({ foto: upload.target.result })
        };
    }

    handleImageChange(e, nameState) {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            switch (nameState) {
                case 'fotocomprovendereco':
                    this.setState({ fotocomprovendereco: reader.result });
                case 'fotomotorista':
                    this.setState({ fotomotorista: reader.result });
                case 'fotocnh':
                    this.setState({ fotocnh: reader.result });
                case 'fotoveiculo':
                    this.setState({ fotoveiculo: reader.result });
                case 'fotocrlv':
                    this.setState({ fotocrlv: reader.result });
                case 'fotoantecedente':
                    this.setState({ fotoantecedente: reader.result });
                default:
                    break;
            }
        };
    };
    // <= Carregamento de Img (Old)

    // => Carregamento de Img (New)
    handleChange = (e, nameState) => {
        if (e.target.files[0]) {

            var reader = new FileReader();
            var file = e.target.files[0];

            reader.readAsDataURL(file);
            reader.onload = upload => {

                switch (nameState) {
                    case 'fotocomprovendereco':
                        this.setState({ fotocomprovendereco: file, fotocomprovenderecopreview: upload.target.result });
                        break;
                    case 'fotomotorista':
                        this.setState({ fotomotorista: file, fotomotoristapreview: upload.target.result });
                        break;
                    case 'fotocnh':
                        this.setState({ fotocnh: file, fotocnhpreview: upload.target.result });
                        break;
                    case 'fotoveiculo':
                        this.setState({ fotoveiculo: file, fotoveiculopreview: upload.target.result });
                        break;
                    case 'fotocrlv':
                        this.setState({ fotocrlv: file, fotocrlvpreview: upload.target.result });
                        break;
                    case 'fotoantecedente':
                        this.setState({ fotoantecedente: file, fotoantecedentepreview: upload.target.result });
                        break;
                    default:
                        break;
                }
            };
        };
    }

    handleUpload = async idDriver => {
        const { fotocomprovendereco, fotomotorista, fotocnh,
            fotoveiculo, fotocrlv, fotoantecedente } = this.state;

        const pics = await [
            {
                name: 'CompEnd',
                file: fotocomprovendereco
            },
            {
                name: 'Motorista',
                file: fotomotorista
            },
            {
                name: 'Cnh',
                file: fotocnh
            },
            {
                name: 'Veiculo',
                file: fotoveiculo
            },
            {
                name: 'CRLV',
                file: fotocrlv
            },
            {
                name: 'Antecedente',
                file: fotoantecedente
            }
        ]

        for (let pic of pics) {
            //console.warn(pic);
            const uploadTask = await storage.ref(`${idDriver.toString()}/${pic.name}`).put(pic.file);
            /* uploadTask.on(
                 'state_changed',
                 snapshot => { },
                 error => console.log('Error'),
                 () => {
                      storage.ref('teste')
                          .child('IMG-20191221-WA0004.jpg')
                          .getDownloadURL()
                          .then(resUrl => setUrl(resUrl))
                 }
             )*/
        }
        this.setState({
            modalshow: true,
            modalheader: 'Cadastro',
            modaltitle: 'Cadastro de Entregador',
            modalmsg: 'Cadastrado realizado com sucesso, entraremos em contato em breve!',
            modaltextbutton: 'Ok, entendi!',
            modalactionbutton: () => this.props.history.push('/'),
        });
    }
    // <= Carregamento de Img (New)

    // Cadastrar
    register = async () => {
        this.setState({ loading: true });
        const {
            razao, fantasia, cpfcnpj, fotomotorista, fotocnh, cep, endereco, numero,
            bairro, cidade, uf, fotocomprovendereco, fone, email, site, marca, modelo,
            ano, cor, placa, renavam, fotoveiculo, fotocrlv, fotoantecedente,
            usuario, senha, senhaok
        } = this.state;

        // Remover máscara dos campos
        let _cpfcnpj = cpfcnpj.replace(/[^\d]+/g,'');
        let _cep = cep.replace(/[\(\)\.\s-]+/g,'');
        let _fone = fone.replace(/[\(\)\.\s-]+/g,'');

        if (
            ValidarNull(razao) &&
            ValidarNull(fantasia) &&
            ValidarNull(_cpfcnpj) &&
            ValidarNull(fotomotorista) &&
            ValidarNull(fotocnh) &&

            ValidarNull(_cep) &&
            ValidarNull(endereco) &&
            ValidarNull(numero) &&
            ValidarNull(bairro) &&
            ValidarNull(cidade) &&
            ValidarNull(uf) &&
            ValidarNull(fotocomprovendereco) &&
            ValidarNull(fotoantecedente) &&

            ValidarNull(_fone) &&
            ValidarNull(email) &&

            ValidarNull(marca) &&
            ValidarNull(modelo) &&
            ValidarNull(ano) &&
            ValidarNull(cor) &&
            ValidarNull(placa) &&
            ValidarNull(renavam) &&
            ValidarNull(fotoveiculo) &&
            ValidarNull(fotocrlv) &&

            ValidarNull(usuario) &&
            senhaok
        ) {

            const driver = await {
                razao, fantasia, cpfcnpj: _cpfcnpj, cep: _cep, endereco, numero, bairro, cidade, uf,
                fotocomprovendereco, fone: _fone, email, site, marca, modelo, ano, cor, placa, renavam,
                usuario, senha
            }
            const ret = await ApiDriver.postDriver(driver);
            console.log(ret);
            if (ret.status === 200) {
                await this.handleUpload(ret.data.idDriver);
                this.setState({ loading: false });
            } else {
                this.setState({
                    loading: false,
                    modalshow: true,
                    modalheader: 'Cadastro',
                    modaltitle: 'Cadastro de Entregador',
                    modalmsg:
                        'Não conseguimos realizar seu cadastro. ' +
                        'Por favor, verifique o preenchimento de todos os campos obrigatórios e tente novamente, considere usar imagens menores também! ',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });
            }

        } else {
            this.setState({
                loading: false,
                modalshow: true,
                modalheader: 'Cadastro',
                modaltitle: 'Cadastro de Entregador',
                modalmsg:
                    'Não conseguimos realizar seu cadastro. ' +
                    'Por favor, verifique o preenchimento de todos os campos obrigatórios e tente novamente, considere usar imagens menores também! ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    render() {

        const {
            razao, fantasia, cpfcnpj, cep, endereco, numero, bairro, cidade, uf,
            fone, email, site, marca, modelo, ano, cor, placa, renavam,
            usuario, senha, confirmsenha, senhaok, forcasenha, barcolor,
            modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton,
            fotocomprovenderecopreview, fotomotoristapreview, fotocnhpreview,
            fotoveiculopreview, fotocrlvpreview, fotoantecedentepreview,
            loading
        } = this.state;

        return (
            <>
                {modalshow &&
                    <Modal header={modalheader} title={modaltitle}
                        message={modalmsg}
                        textButton={modaltextbutton}
                        actionButton={modalactionbutton} />
                }
                <div className='body-register p-3'>
                    <div className='cod-md-8 card-register p-3'>
                        <h2 className='title text-center'>Motorista, cadastre-se agora!</h2>
                        <br />

                        <label className='label'>* campos obrigatórios</label>

                        <br />
                        <h3 className='title'>Dados</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Nome Completo *</label>
                                <input className='form-control' value={razao} maxLength='100' onChange={e => this.setState({ razao: e.target.value })} />
                            </div>
                            <div className="form-group col-md-6">
                                <label className='label'>Nome no aplicativo *</label>
                                <input className='form-control' value={fantasia} maxLength='100' onChange={e => this.setState({ fantasia: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>CPF *</label>
                                <input className='form-control'
                                       value={cpfcnpj} 
                                       maxLength='14'
                                       onChange={e => this.setState({ cpfcnpj: mCNPJCPF(e.target.value) })} />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-6'>
                                <label className="label">Foto do motorista *</label>
                                <br />
                                <input type="file"
                                    accept="image/*"
                                    onChange={e => this.handleChange(e, 'fotomotorista')}
                                />
                                <img src={fotomotoristapreview} className='img-driver' />
                            </div>
                            <div className='form-group col-md-6'>
                                <label className="label">Foto da CNH *</label>
                                <br />
                                <input type="file"
                                    accept="image/*"
                                    onChange={e => this.handleChange(e, 'fotocnh')}
                                />
                                <img src={fotocnhpreview} className='img-driver' />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-6'>
                                <label className="label">Foto dos antecedentes *</label>
                                <br />
                                <input type="file"
                                    accept="image/*"
                                    onChange={e => this.handleChange(e, 'fotoantecedente')}
                                />
                                <img src={fotoantecedentepreview} className='img-driver' />
                            </div>
                        </div>

                        <br />
                        <h3 className='title'>Endereço</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-3">
                                <label className='label'>CEP *</label>
                                <input className='form-control' 
                                       value={cep} 
                                       maxLength='10'
                                       onChange={e => this.setState({ cep: mCEP(e.target.value) })} />
                            </div>
                            <div className="form-group col-md-5">
                                <br />
                                <button className='btn btn-info' onClick={_ => this.handleCep()}>
                                    Pesquisar
                            </button>
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Endereço *</label>
                                <input className='form-control' value={endereco} maxLength='150' onChange={e => this.setState({ endereco: e.target.value })} />
                            </div>
                            <div className="form-group col-md-2">
                                <label className='label'>Número *</label>
                                <input className='form-control' value={numero} maxLength='6' onChange={e => this.setState({ numero: e.target.value })} />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Bairro *</label>
                                <input className='form-control' value={bairro} maxLength='80' onChange={e => this.setState({ bairro: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Cidade *</label>
                                <input className='form-control' value={cidade} maxLength='150' onChange={e => this.setState({ cidade: e.target.value })} />
                            </div>

                            <div className="form-group col-md-2">
                                <label className='label'>UF *</label>
                                <select className="custom-select" id="uf" value={uf} onChange={e => this.setState({ uf: e.target.value })}>
                                    {
                                        ufs.map(sg =>
                                            <option value={sg}>{sg}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className='row col-md-12'>
                                <div className='form-group col-md-6'>
                                    <label className="label">Foto do comprovante de endereço *</label>
                                    <br />
                                    <input type="file"
                                        accept="image/*"
                                        onChange={e => this.handleChange(e, 'fotocomprovendereco')}
                                    />
                                    <img src={fotocomprovenderecopreview} className='img-driver' />
                                </div>
                            </div>
                        </div>

                        <br />
                        <h3 className='title'>Contato</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Fone *</label>
                                <input className='form-control' value={fone} onChange={e => this.setState({ fone: FormatPhoneMask(e.target.value) })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>E-mail *</label>
                                <input className='form-control' 
                                       value={email} 
                                       maxLength='100' 
                                       onChange={e => this.setState({ email: e.target.value })} 
                                       onBlur={e => this.handleEmail(e.target.value)}
                                />
                            </div>

                            <div className="form-group col-md-6">
                                <label className='label'>Site</label>
                                <input className='form-control' value={site} maxLength='150' onChange={e => this.setState({ site: e.target.value })} />
                            </div>
                        </div>

                        <br />
                        <h3 className='title'>Veículo</h3>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-4'>
                                <label className='label'>Marca *</label>
                                <input className='form-control' value={marca} maxLength="50" onChange={e => this.setState({ marca: e.target.value.toUpperCase() })} />
                            </div>
                            <div className='form-group col-md-4'>
                                <label className='label'>Modelo *</label>
                                <input className='form-control' value={modelo} maxLength="50" onChange={e => this.setState({ modelo: e.target.value.toUpperCase() })} />
                            </div>
                            <div className='form-group col-md-4'>
                                <label className='label'>Ano *</label>
                                <input className='form-control' value={ano}  maxLength="4" onChange={e => this.setState({ ano: somenteNumeros(e.target.value) })} />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-4'>
                                <label className='label'>Cor *</label>
                                <input className='form-control' value={cor} maxLength="20" onChange={e => this.setState({ cor: e.target.value.toUpperCase() })} />
                            </div>
                            <div className='form-group col-md-4'>
                                <label className='label'>Placa *</label>
                                <input className='form-control' value={placa} maxLength="7" onChange={e => this.setState({ placa: e.target.value.toUpperCase() })} />
                            </div>
                            <div className='form-group col-md-4'>
                                <label className='label'>Renavam *</label>
                                <input className='form-control' value={renavam} maxLength="15" onChange={e => this.setState({ renavam: e.target.value })} />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-6'>
                                <label className="label">Foto do veiculo *</label>
                                <br />
                                <input type="file"
                                    accept="image/*"
                                    onChange={e => this.handleChange(e, 'fotoveiculo')}
                                />
                                <img src={fotoveiculopreview} className='img-driver' />
                            </div>
                            <div className='form-group col-md-6'>
                                <label className="label">Foto do documento (CRLV) *</label>
                                <br />
                                <input type="file"
                                    accept="image/*"
                                    onChange={e => this.handleChange(e, 'fotocrlv')}
                                />
                                <img src={fotocrlvpreview} className='img-driver' />
                            </div>
                        </div>
                        <br />

                        <h3 className='title'>Login</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-4">
                                <label className='label'>Usuário *</label>
                                <input className='form-control' value={usuario} maxLength='20' onChange={e => this.setState({ usuario: e.target.value })} />
                            </div>

                            <div className="form-group col-md-4">
                                <label className='label'>Senha *</label>&nbsp;<small className='muted-register'>(6 á 12 dígitos)</small>
                                <input className='form-control' value={senha} type='password' maxLength={12} onChange={e => this.setSenha(e.target.value)} />
                                <small className='muted-register'>Força da senha</small>
                                <div className="progress">
                                    <div className={`progress-bar ${barcolor}`} role="progressbar" style={{ width: forcasenha }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label className='label'>Confirmar senha *</label>
                                <input className='form-control' value={confirmsenha} type='password' maxLength={12} onChange={e => this.setConfirmSenha(e.target.value)} />
                                {confirmsenha.length > 0 &&
                                    <small className='muted-register'>
                                        {
                                            senhaok
                                                ? 'Confirmação OK!'
                                                : 'Senhas não correspondem!'
                                        }
                                    </small>
                                }
                            </div>
                        </div>

                        <br />

                        <div className='col-md-12 left-register'>
                            <button className='btn btn-success btn-entrar' onClick={_ => this.register()} disabled={loading}>{loading ? 'Cadastrando...' : 'Cadastrar'}</button>
                        </div>
                    </div>
                    <br />
                </div>
            </>
        )
    }
}