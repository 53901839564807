import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyD79UroLzTq04PHgPlTn4WleonoLBJrtUI",
    authDomain: "sameday-web.firebaseapp.com",
    databaseURL: "https://sameday-web.firebaseio.com",
    projectId: "sameday-web",
    storageBucket: "sameday-web.appspot.com",
    messagingSenderId: "642053655905",
    appId: "1:642053655905:web:881626cdf23139cb3cc920"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };