import React from 'react';
import './approve-driver.css';
import ApiAdmin from '../../api/admin';
import { storage } from '../../firebase';
import Modal from '../modal';

export default class ApproveDriver extends React.Component {

    state = {
        driver: '',
        idMotorista: '',
        motivo: '',
        status: '',
        openImage: false,
        imageSelected: '',

        CompEnd: null,
        Motorista: null,
        Cnh: null,
        Veiculo: null,
        CRLV: null,
        Antecedente: null,
    }

    componentDidMount = async () => {
        const driver = await ApiAdmin.getDriverById(this.props.idMotorista).then(res => res.data).catch(res => []);
        this.loadImages(this.props.idMotorista);
        this.setState({ driver });
    }

    async updateStatus(status) {
        
        const statusDriver = await {
            idDriver: this.props.idMotorista,
            status,
            motivo: this.state.motivo,
        }
        console.log(statusDriver);
        const ret = await ApiAdmin.putUpdateStatusDriver(statusDriver);
        console.log(ret);

        if(ret.status === 200){

            this.props.handleClose();
        }
        else{
            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'Aprovar/Reprovar Motorista',
                modalmsg:
                    'Não foi possível atualizar o motorista. Verifique e tente novamente. ',
                modaltextbutton: 'Ok!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    async loadImages(idDriver) {
        const pics = [
            'CompEnd', 'Motorista', 'Cnh',
            'Veiculo', 'CRLV', 'Antecedente'
        ]
        for (let pic of pics) {
            const ref = await storage.ref(`${idDriver.toString()}/${pic}`);
            const url = await ref.getDownloadURL();
            await this.setImages(url, pic);
        }
    }

    async setImages(url, nameState) {
        switch (nameState) {
            case 'CompEnd':
                this.setState({ CompEnd: url });
            case 'Motorista':
                this.setState({ Motorista: url });
            case 'Cnh':
                this.setState({ Cnh: url });
            case 'Veiculo':
                this.setState({ Veiculo: url });
            case 'CRLV':
                this.setState({ CRLV: url });
            case 'Antecedente':
                this.setState({ Antecedente: url });
            default:
                break;
        }
    }


    openImage(image) {
        this.setState({ openImage: true, imageSelected: image });
    }

    render() {
        const { idMotorista, actionButton, motivo } = this.props;
        const { openImage, imageSelected,
            CompEnd, Motorista, Cnh,
            Veiculo, CRLV, Antecedente, 
            modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton } = this.state;

        return (
            <div className="modal-body">
                {
                    openImage &&
                    <ImageView image={imageSelected} handleClose={_ => this.setState({ openImage: false })} />
                }
                {
                    modalshow &&
                    <Modal header={modalheader} title={modaltitle}
                    message={modalmsg}
                    textButton={modaltextbutton}
                    actionButton={modalactionbutton} />

                }
                <div className="card-modal-body">
                    <div className="card-header card-modal-full">
                        <h5>{this.props.idMotorista}</h5>
                        <button className='button-close' onClick={this.props.handleClose}>X</button>
                    </div>
                    <div className="card-body scroll-bar">
                        <h5 className="card-title">Aprovar/Reprovar Motorista </h5>
                        <br />

                        <h4>Dados</h4>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Nome Completo</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].razao : ''} readOnly />
                            </div>
                            <div className="form-group col-md-6">
                                <label >Nome no aplicativo</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].fantasia : ''} readOnly />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >CPF</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].cpfcnpj : ''} readOnly />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-6'>
                                <label >Foto do motorista</label>
                                <br />
                                {this.state.driver.length > 0 &&
                                    <img src={Motorista} className='card-image-min' onClick={_ => this.openImage(Motorista)} />
                                }
                            </div>
                            <div className='form-group col-md-6'>
                                <label >Foto da CNH</label>
                                <br />
                                {this.state.driver.length > 0 &&
                                    <img src={Cnh} className='card-image-min' onClick={_ => this.openImage(Cnh)} />
                                }
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-6'>
                                <label >Foto dos antecedentes</label>
                                <br />
                                {this.state.driver.length > 0 &&
                                    <img src={Antecedente} className='card-image-min' onClick={_ => this.openImage(Antecedente)} />
                                }
                            </div>
                        </div>
                        <br />

                        <h4>Endereço</h4>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-3">
                                <label>CEP</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].cep : ''} readOnly />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label>Endereço</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].endereco : ''} readOnly />
                            </div>
                            <div className="form-group col-md-2">
                                <label >Número</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].numero : ''} readOnly />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Bairro</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].bairro : ''} readOnly />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Cidade</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].cidade : ''} readOnly />
                            </div>

                            <div className="form-group col-md-2">
                                <label >UF</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].uf : ''} readOnly />
                            </div>
                            <div className='row col-md-12'>
                                <div className='form-group col-md-6'>
                                    <label >Foto do comprovante de endereço</label>
                                    <br />
                                    {this.state.driver.length > 0 &&
                                        <img src={CompEnd} className='card-image-min' onClick={_ => this.openImage(CompEnd)} />
                                    }
                                </div>
                            </div>
                        </div>
                        <br />

                        <h4 >Contato</h4>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Fone</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].fone : ''} readOnly />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >E-mail</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].email : ''} readOnly />
                            </div>
                        </div>
                        <br />

                        <h4>Veículo</h4>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-4'>
                                <label >Marca</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].marca : ''} readOnly />
                            </div>
                            <div className='form-group col-md-4'>
                                <label >Modelo</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].modelo : ''} readOnly />
                            </div>
                            <div className='form-group col-md-4'>
                                <label >Ano</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].ano : ''} readOnly />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-4'>
                                <label >Cor</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].cor : ''} readOnly />
                            </div>
                            <div className='form-group col-md-4'>
                                <label >Placa</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].placa : ''} readOnly />
                            </div>
                            <div className='form-group col-md-4'>
                                <label >Renavam</label>
                                <input className='form-control' value={this.state.driver.length > 0 ? this.state.driver[0].renavam : ''} readOnly />
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-6'>
                                <label >Foto do veiculo</label>
                                <br />
                                {this.state.driver.length > 0 &&
                                    <img src={Veiculo} className='card-image-min' onClick={_ => this.openImage(Veiculo)} />
                                }

                            </div>
                            <div className='form-group col-md-6'>
                                <label>Foto do documento (CRLV)</label>
                                <br />
                                {this.state.driver.length > 0 &&
                                    <img src={CRLV} className='card-image-min' onClick={_ => this.openImage(CRLV)} />
                                }
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <label >Motivo da reprovação (informe apenas se reprovar) </label>
                            <input maxLength="400" className='form-control' value={motivo} onChange={e => this.setState({ motivo: e.target.value })} />
                        </div>

                    </div>
                    <div className='card-modal-footer'>
                        <button class="btn btn-primary" onClick={_ => this.updateStatus('AP')}>Aprovar</button>
                        &nbsp;&nbsp;
                        <button class="btn btn-danger" onClick={_ => this.updateStatus('RP')}>Reprovar</button>
                    </div>
                </div>
            </div>
        );
    }
}

class ImageView extends React.PureComponent {
    render() {
        return (
            <div className='card-image-div'>
                <img src={this.props.image} className='card-image-view' />
                <br />
                <button className='btn btn-danger' onClick={this.props.handleClose}>X Fechar</button>
            </div>
        )
    }
}