import React from 'react';
import { Link } from 'react-router-dom';
import { ValidarNull } from '../../common/functions';
import './index.css';

export default class ClientDrawer extends React.PureComponent {
    state = {
        open: false,
        user: {},

        nameRoute: '',
        dashRoute: '/client-dashboard',
        newOrderRoute: '/client-new-order',
        ordersRoute: '/client-orders',
        profileRoute: '/client-profile',
        reportsRoute: '/client-reports',
    }
    componentDidMount = async () => {
        let user = await localStorage.getItem('user');
        user = await JSON.parse(user);
        this.setState({ user, nameRoute: window.location.pathname });

    }

    openNav() {
        const { open } = this.state;
        this.setState({ open: !open });
    }

    render() {
        const {
            open, user, nameRoute, dashRoute, newOrderRoute,
            ordersRoute, reportsRoute, profileRoute } = this.state;
        const { content } = this.props;

        return (
            <>
                <div className="sidenav" style={{ width: open ? '250px' : '0px' }}>
                    <br />
                    <br />
                    {ValidarNull(user) &&
                        <>
                            <h5 style={{ color: '#EEE', marginLeft: '15px' }}>{user.razao}</h5>
                            <h5 style={{ color: '#EEE', marginLeft: '15px' }}>{user.nome}</h5>
                        </>
                    }
                    <hr />
                    <Link
                        to={{ pathname: '/client-dashboard' }}
                        className={dashRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Dashboard
                    </Link>
                    <Link
                        to={{ pathname: '/client-new-order' }}
                        className={newOrderRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Pedido
                    </Link>
                    <Link
                        to={{ pathname: '/client-orders' }}
                        className={ordersRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Pedidos
                    </Link>
                    <Link
                        to={{ pathname: '/client-reports' }}
                        className={reportsRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Relatórios
                    </Link>
                    <Link
                        to={{ pathname: '/client-profile' }}
                        className={profileRoute == nameRoute ? 'linkSelected' : 'linkNoSelected'}
                    >
                        Perfil
                    </Link>
                </div>
                <a href='#' className="btn-nav" style={{ marginLeft: open ? '190px' : '0px' }} onClick={() => this.openNav()}>
                    <i className="ti-menu"></i>
                </a>
                <div className='background-primary'>
                    {content}
                </div>
            </>
        );
    }
}
