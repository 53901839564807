import React from 'react';
import './index.css';

const Driver = () => {
    return (
        <div>
            Driver
        </div>
    )
}

export default Driver;