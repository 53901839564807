import React from "react";
import Header from "../../home/header";
import Footer from "../../home/footer";
import './styles.css';

const FractionalLogistcs = () => {
    return (
        <>
            <Header />
            <div className="content-container">

                <section className="description">
                    <h1 className="title">Logistica Fracionada</h1>
                    <p>A logística fracionada da SameDay é uma solução projetada para atender empresas que precisam enviar pequenas quantidades de produtos para diversos destinos. Ao compartilhar o mesmo veículo de transporte entre múltiplos embarcadores, conseguimos realizar operações logísticas de maneira mais ágil e econômica. Isso é particularmente útil para negócios que precisam de frequentes envios em menor volume, permitindo maior regularidade nas entregas e redução significativa nos custos.</p>
                </section>

                <section>
                    <h2>Como funciona?</h2>
                    <p>Na SameDay, otimizamos a logística fracionada desde o recebimento das mercadorias até a entrega ao destino final. As mercadorias de diferentes embarcadores são consolidadas em nossa plataforma, que utiliza inteligência artificial para agrupar os pedidos com destinos semelhantes, maximizando o uso do espaço e reduzindo os custos operacionais. Empregamos tecnologia avançada para separar e processar os pedidos, garantindo rapidez e precisão. Por fim, as mercadorias são distribuídas por meio de rotas otimizadas, assegurando entregas eficientes e pontuais. Essa abordagem integrada proporciona flexibilidade e economia para nossos clientes.</p>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default FractionalLogistcs;