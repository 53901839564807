import React from 'react';
import ApiAdmin from '../../api/admin';
import { Link } from "react-router-dom";
import AdminDrawer from '../admin-drawer';

import { FormatDateTime, valorStr } from '../../common/functions';
import './index.css';
import ApproveDriver from '../../components/approve-driver/approve-driver';

export default class ValidateDrivers extends React.Component {
    render() {
        return (
            <AdminDrawer content={<Page />} />
        );
    }
}

class Page extends React.PureComponent {
    state = {
        drivers: [],
        statusOpen: false,
    }

    componentDidMount = async () => {

        const drivers = await ApiAdmin.getPendingDrivers().then(res => res.data).catch(res => []);
        this.setState({ drivers, modalshow: false, driverId: '', });
    }

    render() {
        const { drivers, statusOpen, modalshow, driverId } = this.state;
        return (
            <>
                <div className="container m-3">
                    <h1 className="text-center">Motoristas Pendentes</h1>
                    <hr />
                    <br />
                    <div>
                        <DriverTable drivers={drivers} />
                    </div>
                </div>
            </>
        );
    }
}

class DriverTable extends React.Component {
    render() {
        const { drivers } = this.props;
        return (
            <>
                <table className="table table-striped table-hover">
                    <thead className="thead-dark">
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Data de Cadastro</th>
                        <th>Ações</th>
                    </thead>
                    <tbody>
                        {drivers.map((driver, idx) => <DriverDetail key={idx} driver={driver} idx={idx} />)}
                    </tbody>
                </table>
            </>
        )
    }
}

class DriverDetail extends React.PureComponent {

    state = {
        modalshow: false,
    }

    openModal() {
        this.setState({
            modalshow: true,
        });
    }

    handleClose = async () => {

        const drivers = await ApiAdmin.getPendingDrivers().then(res => res.data).catch(res => []);
        this.setState({ drivers, modalshow: false, driverId: '', });
    }

    render() {
        const { driver } = this.props;
        const { modalshow } = this.state;

        return (
            <>
                {modalshow &&
                    <ApproveDriver idMotorista={driver.id} handleClose={_ => this.handleClose()} />
                }
                <tr>

                    <td>{driver.id}</td>
                    <td>{driver.razao}</td>
                    <td>{FormatDateTime(driver.dtcad)}</td>
                    <td>
                        <button className='btn btn-primary'
                            onClick={() => this.openModal()}  >Avaliar dados</button>
                    </td>
                </tr>
            </>
        )
    }
}