import React from 'react';
import './index.css';
import AdminDrawer from '../admin-drawer';

export default class Dashboard extends React.Component {
    render() {
        return (
            <AdminDrawer content={<Page />} />
        );
    }
}

class Page extends React.PureComponent {
    render() {
        return (
            <>
                <h1 className="text-center">Dashboard</h1>
                <hr />
                <br />
                <div className="row col-md-12 d-flex justify-content-around">
                    <div className="col m-3 rounded shadow-sm bg-white">
                        <div className="row col justify-content-between">
                            <div className="rounded shadow bg-primary text-center"
                                style={{ width: '80px', height: '80px', marginTop: '-20px' }}>
                                <i className="fas fa-clipboard-list icon-card"></i>
                            </div>
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <span className="text-card-title">+200</span>
                            </div>
                        </div>
                        <hr />
                        <span className="text-card-desc">Motoristas cadastrados</span>
                    </div>
                    <div className="col m-3 rounded shadow-sm bg-white">
                        <div className="row col justify-content-between">
                            <div className="rounded shadow bg-warning text-center"
                                style={{ width: '80px', height: '80px', marginTop: '-20px' }}>
                                <i className="fas fa-user-friends icon-card"></i>
                            </div>
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <span className="text-card-title">+50</span>
                            </div>
                        </div>
                        <hr />
                        <span className="text-card-desc">Clientes cadastrados</span>
                    </div>
                    <div className="col m-3 rounded shadow-sm bg-white">
                        <div className="row col justify-content-between">
                            <div className="rounded shadow bg-danger text-center"
                                style={{ width: '80px', height: '80px', marginTop: '-20px' }}>
                                <i className="fas fa-dolly icon-card"></i>
                            </div>
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <span className="text-card-title">+5.000</span>
                            </div>
                        </div>
                        <hr />
                        <span className="text-card-desc">KM's rodados</span>
                    </div>
                    <div className="col m-3 rounded shadow-sm bg-white">
                        <div className="row col justify-content-between">
                            <div className="rounded shadow bg-success text-center"
                                style={{ width: '80px', height: '80px', marginTop: '-20px' }}>
                                <i className="fas fa-money-check-alt icon-card"></i>
                            </div>
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <span className="text-card-title">+R$38mil</span>
                            </div>
                        </div>
                        <hr />
                        <span className="text-card-desc">Em mercadoria entregue</span>
                    </div>
                </div>
            </>
        );
    }
}