import React from 'react';
import './index.css';
import { ValidarNull } from '../../common/functions';
import Modal from '../../components/modal';
import { Redirect } from 'react-router';

export default class AdminLogin extends React.Component {
    state = {
        usuario: '',
        senha: '',
        redirect: false,

        modalshow: false,
        modalheader: '',
        modaltitle: '',
        modalmsg: '',
        modaltextbutton: '',
        actionButton: () => this.setState({ modalshow: false }),
    }

    async entrar() {
        const { usuario, senha } = this.state;
        if (ValidarNull(usuario) && ValidarNull(senha)) {
            if (usuario === 'admin' && senha === 'SameDay2020') {
                //window.location = '/admin-dashboard';
                this.setState({ redirect: '/admin-dashboard' })
            }
            else {
                this.setState({
                    modalshow: true,
                    modalheader: 'Ops!',
                    modaltitle: 'Dados incorretos!',
                    modalmsg:
                        'Usuário ou senha incorretos. Por favor, verifique os dados e tente novamente.',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });
            }
        }
        else {
            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'Dados incorretos!',
                modalmsg:
                    'Por favor, informe o seu usuário e senha. ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to="/admin-dashboard" />;
        }
        const { usuario, senha,
            modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton } = this.state;
        return (
            <>
                {modalshow &&
                    <Modal header={modalheader} title={modaltitle}
                        message={modalmsg}
                        textButton={modaltextbutton}
                        actionButton={modalactionbutton} />
                }
                <div className='body'>
                    <div className='col-md-5 card p-3'>
                        <h2 className='title'>Login</h2>
                        <br />
                        <div className='col-md-10'>
                            <label className='label'>Usuário</label>
                            <input className='form-control' value={usuario} onChange={e => this.setState({ usuario: e.target.value })} />
                        </div>
                        <br />
                        <div className='col-md-10'>
                            <label className='label'>Senha</label>
                            <input className='form-control' type='password'
                                value={senha}
                                onChange={e => this.setState({ senha: e.target.value })}
                                onKeyDown={e => { (e.key === 'Enter') && this.entrar(); }}
                            />
                        </div>
                        <br />
                        <button className='btn btn-success btn-entrar' onClick={_ => this.entrar()}>Entrar</button>
                        <br />
                    </div>
                </div>
            </>
        )
    }
}