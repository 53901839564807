import Api from '../index';

export const ApiClient = {
    async getById(id) {
        return await Api.Route()
            .get(`client/getById/${id}`)
            .then(res => res.data)
            .catch(e => e);
    },
    async getAll() {
        return await Api.Route()
            .get(`client/getAll`)
            .then(res => res.data)
            .catch(e => e);
    },
    async postClient(client) {
        return await Api.Route()
            .post(`client/create`, client)
            .then(res => res)
            .catch(e => e);
    },
    async postAddEmail(email) {
        return await Api.Route()
            .post(`client/addEmail`, email)
            .then(res => res)
            .catch(e => e);
    },
    async getReport(idEmpresa, ano, mes, tipoPeriodo){
        return await Api.Route()
            .get(`client/getReport/${idEmpresa}/${ano}/${mes}/${tipoPeriodo}`)
            .then(res => res.data)
            .catch(e => e);
    }
}

export default ApiClient;
