import React from "react";
import './index.css';

import WaveShape from '../../img/hero-bg-shape-1.svg';
import ApiClient from '../../api/client';

import { validarEmail } from '../../common/functions';
import Modal from '../../components/modal';

export default class Landing extends React.Component {

  state = {
    email: '',

    modalshow: false,
    modalheader: '',
    modaltitle: '',
    modalmsg: '',
    modaltextbutton: '',
    actionButton: () => this.setState({ modalshow: false }),
  }

    // E-mail
    addEmail = async _ => {

      const { email } = this.state;

      if(email.length > 0){

        const emailAdd = {
          email
        }

        const res = await ApiClient.postAddEmail(emailAdd);

        if (res.status === 200) {
          this.setState({
              modalshow: true,
              modalheader: 'Lista de e-mails',
              modaltitle: 'Cadastro efetuado com sucesso',
              modalmsg: 'Você ficará por dentro das nossas novidades!',
              modaltextbutton: 'Ok, entendi!',
              modalactionbutton: () => this.setState({ modalshow: false }),
          });
      } else {
          this.setState({
              modalshow: true,
              modalheader: 'Ops!',
              modaltitle: res.data.msg || 'Lista de e-mails',
              modalmsg:
                  'Não conseguimos adicionar o e-mail a lista, ' +
                  'por favor, tente novamente!',
              modaltextbutton: 'Ok, entendi!',
              modalactionbutton: () => this.setState({ modalshow: false }),
          });
      }
    }
  }

  handleEmail(email){

    if(email.length > 0){

      if(!validarEmail(email)){

        this.setState({
            email: '',
        });

        this.setState({
            modalshow: true,
            modalheader: 'Ops!',
            modaltitle: 'E-mail inválido',
            modalmsg:
                'Digite um e-mail válido e tente novamente! ',
            modaltextbutton: 'Ok, entendi!',
            modalactionbutton: () => this.setState({ modalshow: false }),
        });
    }
    }
  }

  render() {

    const { email, modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton } = this.state;

    return (
      
      <React.Fragment>

        {modalshow &&
          <Modal header={modalheader} title={modaltitle}
                message={modalmsg}
                textButton={modaltextbutton}
                actionButton={modalactionbutton} />
        }
        
        <section
          className="hero-section pt-100 background-img"
          style={{
            backgroundImage: `url(/image/truck.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between py-5"
            >
              <div className="col-md-7 col-lg-6">
                <div className="hero-content-left text-white">
                  <h1 className="text-white">
                    <span>Same Day</span>
                    <br />Sua entrega no mesmo dia!
                  </h1>
                  <p className="lead">
                    {'Deixe seu e-mail e receba nossas novidades!'}
                  </p>

                  <form method="post" className="subscribe-form">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control input"
                        id="email"
                        name="email"
                        placeholder="email@dominio.com.br"
                        value={ email }
                        onChange={e => this.setState({ email: e.target.value })}
                        onBlur={e => this.handleEmail(e.target.value)} 
                      />
                      <input
                        type="button"
                        text="Enviar"
                        value="Cadastrar"
                        className="button btn solid-btn"
                        onClick={_ => this.addEmail()}
                      />
                    </div>
                  </form>

                  <div className="video-promo-content py-4 d-flex align-items-center">
                    <a
                      href="https://www.youtube.com/watch?v=mErfROsJDn4"
                      className="popup-youtube video-play-icon-without-bip video-play-icon mr-3"
                    >
                      <span className="ti-control-play"></span>
                    </a>{" "}
                    Assista o vídeo de apresentação
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="hero-animation-img">
                  <div className="img-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-img-absolute">
            <img
              src={WaveShape}
              alt="wave shape"
              className="img-fluid"
            />
          </div>
        </section>
      </React.Fragment>
    );
  }
}
