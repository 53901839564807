import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={"footer-top background-img-2 pt-150 pb-5"}
            style={{
              backgroundImage: "linear-gradient(to right, rgba(04, 46, 126, 1), rgba(24, 66, 186, 1), rgba(74, 116, 236, 1))",
              /*backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "cover"*/
            }}
          >
            <div className="container">
              <div className="row justify-content-between" style={{height:200}}>
                 <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src="image/Same_Day_Logo3.png"
                      alt="footer logo"
                      width="120"
                      className="img-fluid mb-3"
                    />
                    <p>
                      Same Day - Entregas no mesmo dia.
                    </p>

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a href="/#" target="_blank" title="Facebook">
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/#" target="_blank" title="Twitter">
                            <span className="ti-twitter"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/#" target="_blank" title="Instagram">
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="/#" target="_blank" title="printerst">
                            <span className="ti-pinterest"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Links úteis</h5>
                    <ul className="list-unstyled">
                      <li className="mb-2">
                        <a href="/#">Política de privacidade</a>
                      </li>
                      <li className="mb-2">
                        <a href="/#">Termos e condições</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Suporte</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        Endereço
                        <br />
                        Cidade
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        <a href="tel:+61283766284">(00) 0000-0000</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:mail@example.com"> mail@example.com</a>
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-world mr-2"></span>
                        <a href="/#"> www.sameday.com.br</a>
                      </li>
                    </ul>
                  </div>
                </div>
               <div className="col-lg-3">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Filiais</h5>
                    <img
                      src="img/map.png"
                      alt="location map"
                      className="img-fluid"
                    />
                  </div>
                </div>
             
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                    Copyrights © 2020. All rights reserved by
                    &nbsp;
                    <a href="/#"><b>Same Day</b></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

