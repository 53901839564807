import React from 'react';
import './index.css';
import AdminDrawer from '../admin-drawer';
import { meses, anos } from '../../common/constants';
import { FormatDateTime, valorStr, ValidarNull } from '../../common/functions';
import ApiClient from '../../api/admin';

export default class Reports extends React.Component {
    render() {
        return <AdminDrawer content={<Page />} />
    }
}

class Page extends React.Component {
    state = {
        user: null,
        ano: '2021',
        mes: '01',
        typePeriod: 1,
        drivers: [],
        companies: [],
        idDriver: 0,
        idCompany: 0,
        data: [
            {
                "id": 17,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-12T03:00:00.000Z",
                "datacoleta": "2020-10-14 00:00:00",
                "dataentrega": "2020-10-15 00:00:00",
                "km": 13.825,
                "valor": 34.5625
            },
            {
                "id": 18,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-12T03:00:00.000Z",
                "datacoleta": "2020-10-14 00:00:00",
                "dataentrega": "2020-10-14 00:00:00",
                "km": 13.825,
                "valor": 34.5625
            },
            {
                "id": 19,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-12T03:00:00.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0,
                "valor": 0
            },
            {
                "id": 20,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-12T03:00:00.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 2.673,
                "valor": 6.6825
            },
            {
                "id": 23,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T20:26:43.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 79.621,
                "valor": 79.621
            },
            {
                "id": 24,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T20:42:53.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 25,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T20:43:47.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 26,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T20:47:03.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 27,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T22:58:01.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 28,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:01:04.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 29,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:05:53.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 30,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:08:17.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 79.621,
                "valor": 79.621
            },
            {
                "id": 31,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:16:29.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 32,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:18:03.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 33,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:18:42.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 34,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:22:10.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 35,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:26:34.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 36,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-18T23:27:18.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 37,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-29T02:26:24.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            },
            {
                "id": 38,
                "idempresa": 1,
                "identregador": 1,
                "datalancto": "2020-10-29T02:40:55.000Z",
                "datacoleta": "2020-10-17 00:00:00",
                "dataentrega": "2020-10-17 00:00:00",
                "km": 0.027,
                "valor": 100
            }
        ],
        total: 999,
    }

    componentDidMount = async () => {

        let user = await localStorage.getItem('user');
        user = await JSON.parse(user);

        const drivers = await ApiClient.getAllDrivers();
        const companies = await ApiClient.getAllCompanies();

        this.setState({ user, drivers, companies });
    }

    getReport = async _ => {

        const { user, ano, mes, typePeriod } = this.state;

        //const data = await ApiClient.getReport(user.idref, ano, mes, typePeriod);

        const data = await ApiClient.getReport(2020, 10, 0);

        const total = ValidarNull(data) ? valorStr(data.reduce((total, item) => total + item.valor, 0)) : 0;

        this.setState({ data, total });
    }

    render() {
        const { typePeriod, data, total, drivers, companies } = this.state;

        console.log(drivers)
        return (
            <div className="m-3">
                <h1 className="text-center">Relatórios Administrativos</h1>
                <hr />
                <br />
                <div className='row justify-content-md-center'>
                    <div className='report-period text-center col col-md-3'>
                        <label for="Ano">Ano:</label>
                        &nbsp;&nbsp;&nbsp;
                        <select name="Ano" id="Ano" className='report-select'>
                            {anos.map(ano => <option value={ano}>{ano}</option>)}
                        </select>
                    </div>
                    <div className='report-period text-center col col-md-3'>
                        <label for="Meses">Mês:</label>
                        &nbsp;&nbsp;&nbsp;
                        <select name="Meses" id="Meses" className='report-select'>
                            {meses.map(mes => <option value={mes.id} >{mes.text}</option>)}
                        </select>
                    </div>
                    <div className='report-period text-center col col-md-3'>
                        <input type='radio' id="quinzena1" name="quinzena1"
                            checked={typePeriod == 1}
                            onClick={() => this.setState({ typePeriod: 1 })} />
                            &nbsp;
                        <label for="quinzena1">1ªQuinzena</label>
                        &nbsp;&nbsp;
                        <input type='radio' id="quinzena2" name="quinzena2"
                            checked={typePeriod == 2}
                            onClick={() => this.setState({ typePeriod: 2 })} />
                            &nbsp;
                        <label for="quinzena2">2ªQuinzena</label>
                        &nbsp;&nbsp;
                        <input type='radio' id="mensal" name="mensal"
                            checked={typePeriod == 3}
                            onClick={() => this.setState({ typePeriod: 3 })} />
                            &nbsp;
                        <label for="mensal">Mensal</label>
                    </div>
                </div>
                <br />
                <div className='row justify-content-md-center'>
                    <div className='report-period text-center col col-md-3'>
                        <label for="motoristas">Motorista: </label>

                        <select name="motoristas" id="motoristas" className="report-select" onChange={e => this.setState({ idDriver: e.target.value })}>
                            {drivers.map(driver => <option value={driver.id}>{driver.razao}</option>)}
                        </select>
                    </div>
                    <div className='report-period text-center col col-md-3'>
                        <label for="empresas">Empresa: </label>

                        <select name="empresas" id="empresas" className="report-select" onChange={e => this.setState({ idCompany: e.target.value })}>
                            {companies.map(company => <option value={company.id}>{company.razao}</option>)}
                        </select>
                    </div>
                    <div className='text-center col col-md-1'>
                        <button className='btn btn-success' onClick={_ => this.getReport()}>Filtrar</button>
                    </div>
                </div>
                <br />
                {data && <Table data={data} />}
                <hr />
                <h1 className="text-right mr-3">Total R${total}</h1>
            </div>
        );
    }
}

class Table extends React.PureComponent {
    render() {
        const { data } = this.props;
        return (
            <table className="table table-striped table-hover">
                <thead className="table-dark">
                    <th>Id</th>
                    <th>Id Entregador</th>
                    <th>Data Lancto</th>
                    <th>Data Coleta</th>
                    <th>Data Entrega</th>
                    <th>KM's</th>
                    <th>Valor</th>
                </thead>
                <tbody>
                    {data.map((dt, idx) => <TableContent key={idx} dt={dt} idx={idx} />)}
                </tbody>
            </table>
        );
    }
}

class TableContent extends React.PureComponent {
    render() {
        const { dt } = this.props;
        return (
            <tr>
                <td>{dt.id}</td>
                <td>{dt.identregador}</td>
                <td>{FormatDateTime(dt.datalancto)}</td>
                <td>{FormatDateTime(dt.datacoleta)}</td>
                <td>{FormatDateTime(dt.dataentrega)}</td>
                <td>{dt.km.toFixed(3)}</td>
                <td>R$ {valorStr(dt.valor)}</td>
            </tr>
        );
    }
}