import Api from '../index';

export const ApiMap = {
    async getDistance(origin, destination) {
        return await Api.Route()
            .get(`map/getDistance?origin=${origin}&destination=${destination}`)
            .then(res => res.data)
            .catch(e => e);
    },

    async getLatLong(cep) {
        return await Api.Route()
            .get(`map/getLatLong/${cep}`)
            .then(res => res.data)
            .catch(e => e);
    },
    async getLocation(cep) {
        return await Api.RouteCep()
            .get(cep + '/json')
            .then(res => res.data)
            .catch(_ => { });
    },

    async postPrice(data) {
        return await Api.Route()
            .post('map/postPrice', data)
            .then(res => res.data)
            .catch(_ => { });
    },
}

export default ApiMap;