import Api from '../index';

export const ApiOrder = {
    async getAll(id) {
        return await Api.Route()
            .get(`order/getAll/${id}`)
            .then(res => res.data)
            .catch(e => e);
    },
    async postOrder(order) {
        return await Api.Route()
            .post(`order`, order)
            .then(res => res)
            .catch(e => e);
    },

}

export default ApiOrder;