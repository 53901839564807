import axios from "axios";

const Api = {
    Route() {
        return axios.create({
            validateStatus: function (status) {
                return (
                    status !== 204 && status !== 401 && status !== 400 && status !== 406
                );
            },
            //baseURL: "https://sameday-api.herokuapp.com/",
            baseURL: "http://localhost:3000/",
            timeout: 120000,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        });

    },
    RouteCep() {
        return axios.create({
            validateStatus: function (status) {
                return (
                    status !== 204 && status !== 401 && status !== 400 && status !== 406
                );
            },
            baseURL:
                "https://viacep.com.br/ws/",
            timeout: 120000,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        });
    },
}

export default Api;