import React from 'react';
import { Link } from 'react-router-dom';
import './modal.css';

export default class Modal extends React.Component {
    render() {
        const { header, title, message, textButton, actionButton, typeButton } = this.props;
        return (
            <div className="modal-alert-body">
                <div className="card card-modal-alert-body" style={{ backgroundColor: '#FFF' }}>
                    <h5 className="card-header card-modal-alert-full">{header}</h5>
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{message}</p>
                        {
                            typeButton == 'link'
                                ? <Link to={{ pathname: actionButton }} className="btn btn-primary">{textButton}</Link>
                                : <button className="btn btn-primary" onClick={actionButton}>{textButton}</button>
                        }

                    </div>
                </div>
            </div>
        );
    }
}