import React, { useState } from 'react';
import Routes from './routes';

import { storage } from './firebase';

function App() {

  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(null);

  const handleChange = e => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  }

  const handleUpload = _ => {
    const uploadTask = storage.ref(`images/${image.name}`).put(image);
    uploadTask.on(
      'state_changed',
      snapshot => { },
      error => console.log('Error'),
      () => {
        storage.ref('teste')
          .child('IMG-20191221-WA0004.jpg')
          .getDownloadURL()
          .then(resUrl => setUrl(resUrl))
      }
    )
  }

  return (
    <Routes />
  );
}

export default App;
