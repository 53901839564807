import React from "react";
import Header from "../../home/header";
import Footer from "../../home/footer";
import './styles.css';

const Partners = () => {
    return (
        <>
            <Header />
            <div className="content-container">

                <section>
                    <h1 className="title">Parceiros</h1>
                    <p>Na SameDay, estamos transformando a logística urbana no Brasil, e você pode ser parte dessa mudança! Nossa rede inovadora de Stock Stores oferece uma oportunidade única para proprietários de imóveis e pontos comerciais aproveitarem espaços ociosos, transformando-os em mini centros de distribuição estratégicos. Ao se tornar um parceiro, você maximiza o uso do seu espaço, gera receita adicional e contribui para uma operação logística mais eficiente e sustentável.</p>
                </section>

                <section className="benefits">
                    <h3>Por que se Tornar um Parceiro SameDay?</h3>
                    <ul>
                        <li><strong>Monetize Espaços Ociosos:</strong> Converta áreas subutilizadas em fontes de renda, aproveitando ao máximo seu imóvel.</li>
                        <li><strong>Inovação e Tecnologia:</strong> Utilize nossa plataforma avançada que integra gestão de inventário, logística fracionada e fulfillment, garantindo operações rápidas e precisas.</li>
                        <li><strong>Expansão de Mercado:</strong> Faça parte de uma rede em crescimento que está redefinindo a logística urbana, conectando empresas a soluções flexíveis e econômicas.</li>
                        <li><strong>Sustentabilidade:</strong> Contribua para uma logística mais verde, com menor dependência de grandes armazéns e uma pegada ambiental reduzida.</li>
                    </ul>

                    <span>Junte-se à SameDay e seja protagonista na criação de um futuro onde a eficiência, a inovação e a sustentabilidade caminham juntas. Entre em contato conosco e descubra como podemos transformar seu espaço em um ponto estratégico para a logística do amanhã.</span>
                </section>

                <section className="content-images">
                   {[1,2,3,4,5,6,7,8,9].map(() => 
                        <img
                            src={"image/Same_Day_Logo.png"}
                            alt="logo"
                            className="img-partners"
                        />
                    )}
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Partners;