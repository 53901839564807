import React from "react";

export default class Pricing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            price: {
                title: "Pricing Packages",
                description:
                    "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
                packages: [
                    {
                        price: "$29",
                        license: "Basic",
                        isFeatured: false,
                        features: [
                            "Push Notifications",
                            "Data Transfer",
                            "SQL Database",
                            "Search & SEO Analytics",
                            "24/7 Phone Support",
                            "2 months technical support",
                            "2+ profitable keyword"
                        ]
                    },
                    {
                        price: "$50",
                        license: "Popular",
                        isFeatured: true,
                        features: [
                            "Push Notifications",
                            "Data Transfer",
                            "SQL Database",
                            "Search & SEO Analytics",
                            "24/7 Phone Support",
                            "6 months technical support",
                            "30+ profitable keyword"
                        ]
                    },
                    {
                        price: "$120",
                        license: "Extended",
                        isFeatured: false,
                        features: [
                            "Push Notifications",
                            "Data Transfer",
                            "SQL Database",
                            "Search & SEO Analytics",
                            "24/7 Phone Support",
                            "1 year technical support",
                            "70+ profitable keyword"
                        ]
                    }
                ]
            },
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="overflow-hidden">
                    <section
                        id="pricing"
                        className={"package-section ptb-100 "}>
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-lg-6">
                                    <div id="accordion-1" className="accordion accordion-faq">
                                        <div className="card">
                                            <div
                                                className="card-header py-4"
                                                id="heading-1-1"
                                                data-toggle="collapse"
                                                role="button"
                                                data-target="#collapse-1-1"
                                                aria-expanded="false"
                                                aria-controls="collapse-1-1"
                                            >
                                                <h6 className="mb-0">
                                                    <span className="ti-truck mr-3"></span>Entrega no mesmo dia
                                                </h6>
                                            </div>
                                            <div
                                                id="collapse-1-1"
                                                className="collapse"
                                                aria-labelledby="heading-1-1"
                                                data-parent="#accordion-1"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Você já esteve prestes a vender um produto mas seu cliente desistiu quando simulou o frete? <br />
                                                        Já superamos este problema.<br />
                                                        Ofereça fretes mais competitivos na sua loja com a opção de entrega no mesmo dia.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div
                                                className="card-header py-4"
                                                id="heading-1-2"
                                                data-toggle="collapse"
                                                role="button"
                                                data-target="#collapse-1-2"
                                                aria-expanded="false"
                                                aria-controls="collapse-1-2"
                                            >
                                                <h6 className="mb-0">
                                                    <span className="ti-layout-grid2 mr-3"></span>A solução logística
                                                </h6>
                                            </div>
                                            <div
                                                id="collapse-1-2"
                                                className="collapse"
                                                aria-labelledby="heading-1-2"
                                                data-parent="#accordion-1"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        A SAMEDAY te ajuda a resolver a logística de sua empresa,<br />
                                                         de uma vez por todas!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div
                                                className="card-header py-4"
                                                id="heading-1-3"
                                                data-toggle="collapse"
                                                role="button"
                                                data-target="#collapse-1-3"
                                                aria-expanded="false"
                                                aria-controls="collapse-1-3"
                                            >
                                                <h6 className="mb-0">
                                                    <span className="ti-clipboard mr-3"></span>Painéis de controle
                                                </h6>
                                            </div>
                                            <div
                                                id="collapse-1-3"
                                                className="collapse"
                                                aria-labelledby="heading-1-3"
                                                data-parent="#accordion-1"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Acesso ao painel  da sameday para controle de entregas.<br />
                                                        Faturamento quinzenal.<br />
                                                        Preços competitivos.<br />
                                                        Sem custo de coleta.*<br />
                                                        Sem faturamento mínimo.<br />
                                                        Sem volume mínimo de envios.<br />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div id="accordion-2" className="accordion accordion-faq">
                                        <div className="card">
                                            <div
                                                className="card-header py-4"
                                                id="heading-2-1"
                                                data-toggle="collapse"
                                                role="button"
                                                data-target="#collapse-2-1"
                                                aria-expanded="false"
                                                aria-controls="collapse-2-1"
                                            >
                                                <h6 className="mb-0">
                                                    <span className="ti-panel mr-3"></span>Como funciona
                                                </h6>
                                            </div>
                                            <div
                                                id="collapse-2-1"
                                                className="collapse"
                                                aria-labelledby="heading-2-1"
                                                data-parent="#accordion-2"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Você poderá gerenciar seu tempo da melhor forma possível, <br />
                                                        possuindo toda a autonomia para trabalhar no seu horário.<br />
                                                        Pagamento é quinzenal diretamente em sua conta <br />
                                                        em até 3 dias úteis após o fechamento.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div
                                                className="card-header py-4"
                                                id="heading-2-2"
                                                data-toggle="collapse"
                                                role="button"
                                                data-target="#collapse-2-2"
                                                aria-expanded="false"
                                                aria-controls="collapse-2-2"
                                            >
                                                <h6 className="mb-0">
                                                    <span className="ti-mobile mr-3"></span>Através do app você:
                                                </h6>
                                            </div>
                                            <div
                                                id="collapse-2-2"
                                                className="collapse"
                                                aria-labelledby="heading-2-2"
                                                data-parent="#accordion-2"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Visualiza coletas e entregas.<br />
                                                        Navegação simultânea através de mapas e rotas.<br />
                                                        Todos os destinatários são avisados com antecedência <br />
                                                        para agilizar suas entregas.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div
                                                className="card-header py-4"
                                                id="heading-2-3"
                                                data-toggle="collapse"
                                                role="button"
                                                data-target="#collapse-2-3"
                                                aria-expanded="false"
                                                aria-controls="collapse-2-3"
                                            >
                                                <h6 className="mb-0">
                                                    <span className="ti-check-box mr-3"></span>Requisitos
                                                </h6>
                                            </div>
                                            <div
                                                id="collapse-2-3"
                                                className="collapse"
                                                aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2"
                                            >
                                                <div className="card-body">
                                                    <p>
                                                        Veículo automotor (máximo 15 anos de uso).<br />
                                                        Veículo e CNH em situação regular.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="row pt-5">
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>How can I pay for this?</h5>
                                        <p>
                                            Intrinsicly implement high standards in strategic theme
                                            areas via inexpensive solutions. Assertively
                                            conceptualize prospective bandwidth whereas client-based
                                            imperatives.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>Is it possible to pay yearly?</h5>
                                        <p>
                                            Assertively iterate user friendly innovation without
                                            open-source markets. Monotonectally extend resource
                                            sucking manufactured products without high-payoff
                                            paradigms. Objectively customize ubiquitous information
                                            before economically sound relationships.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>Do you offer discounts on multiple items?</h5>
                                        <p>
                                            Dramatically target focused testing procedures after
                                            holistic ideas. Collaboratively maximize high-payoff ROI
                                            and value-added products. Distinctively deliver
                                            cooperative collaboration and idea-sharing whereas
                                            customized
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>Is VAT included in plan prices?</h5>
                                        <p>
                                            Distinctively simplify high-quality initiatives for
                                            highly efficient applications. Monotonectally repurpose
                                            integrated customer service after magnetic e-services.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>Will I pay more for some features?</h5>
                                        <p>
                                            Enthusiastically pontificate resource-leveling supply
                                            chains whereas scalable markets. Authoritatively
                                            streamline resource maximizing methods of empowerment
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>
                                            Why are there no limits on the number of messages?
                                        </h5>
                                        <p>
                                            Assertively target turnkey ideas for market-driven
                                            portals. Appropriately e-enable world-class intellectual
                                            capital whereas 2.0 mindshare.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        */}
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}
