import React from 'react';
import './index.css';

import ApiMap from '../../api/map';
import ApiClient from '../../api/client';

import { ValidarNull, mCNPJCPF, mCEP, FormatPhoneMask, validarEmail } from '../../common/functions';
import { ufs } from '../../common/constants';
import Modal from '../../components/modal';
import { isUndefined } from 'lodash';

export default class ClientRegister extends React.Component {

    state = {
        razao: '',
        fantasia: '',
        cpfcnpj: '',
        endereco: '',
        numero: null,
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
        fone: '',
        email: '',
        site: '',

        usuario: '',
        senha: '',
        confirmsenha: '',
        senhaok: false,

        forcasenha: '0%',
        barcolor: 'bg-danger',

        modalshow: false,
        modalheader: '',
        modaltitle: '',
        modalmsg: '',
        modaltextbutton: '',
        actionButton: () => this.setState({ modalshow: false }),
    }

    async setSenha(senha) {
        await this.setState({ senha });
        await this.setConfirmSenha(this.state.confirmsenha);

        if (senha.length < 6) this.setState({ forcasenha: '0%', barcolor: 'bg-danger' });
        if (senha.length == 4) this.setState({ forcasenha: '7%', barcolor: 'bg-danger' });
        if (senha.length == 5) this.setState({ forcasenha: '10%', barcolor: 'bg-danger' });
        if (senha.length == 6) this.setState({ forcasenha: '15%', barcolor: 'bg-danger' });
        if (senha.length == 7) this.setState({ forcasenha: '25%', barcolor: 'bg-warning' });
        if (senha.length == 8) this.setState({ forcasenha: '40%', barcolor: 'bg-warning' });
        if (senha.length == 9) this.setState({ forcasenha: '50%', barcolor: 'bg-info' });
        if (senha.length == 10) this.setState({ forcasenha: '65%', barcolor: 'bg-info' });
        if (senha.length == 11) this.setState({ forcasenha: '80%', barcolor: 'bg-success' });
        if (senha.length == 12) this.setState({ forcasenha: '100%', barcolor: 'bg-success' });

    }

    setConfirmSenha(confirmsenha) {
        const { senha } = this.state;
        this.setState({ confirmsenha });

        if (confirmsenha.length > 0) {
            this.setState({ senhaok: senha == confirmsenha });
        } else {
            this.setState({ senhaok: false });
        }
    }

    // Cep
    handleCep = async _ => {

        const { cep } = this.state;
        if(!isUndefined(cep)){

            const local = await ApiMap.getLocation(cep.replace('.','').replace('-', ''));
            if (ValidarNull(local)) {
                this.setState({
                    cep: local.cep,
                    endereco: local.logradouro,
                    bairro: local.bairro,
                    cidade: local.localidade,
                    uf: local.uf,
                });
            }
            else {
                this.setState({
                    modalshow: true,
                    modalheader: 'Ops!',
                    modaltitle: 'CEP',
                    modalmsg:
                        'Não encontramos o CEP informado. Verifique e tente novamente. ',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });
    
                this.setState({
                    cep: '',
                    endereco: '',
                    bairro: '',
                    cidade: '',
                    uf: 'UF',
                });
            }
        }
        else{
            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'CEP',
                modalmsg:
                    'Não encontramos o CEP informado. Verifique e tente novamente. ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    // E-mail
    handleEmail(email){

        if(!validarEmail(email)){

            this.setState({
                email: ''
            });

            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'E-mail inválido',
                modalmsg:
                    'Digite um e-mail válido e tente novamente! ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    register = async _ => {
        const {
            razao, fantasia, cpfcnpj, endereco, numero, cep,
            bairro, cidade, uf, fone, email, site,
            usuario, senha, confirmsenha, senhaok, forcasenha, barcolor
            , modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton
        } = this.state;

        // Remover máscara dos campos
        let _cpfcnpj = cpfcnpj.replace(/[^\d]+/g,'');
        let _cep = cep.replace(/[\(\)\.\s-]+/g,'');
        let _fone = fone.replace(/[\(\)\.\s-]+/g,'');

        if (
            ValidarNull(razao) &&
            ValidarNull(fantasia) &&
            ValidarNull(_cpfcnpj) &&
            ValidarNull(endereco) &&
            ValidarNull(numero) &&
            ValidarNull(_cep) &&
            ValidarNull(bairro) &&
            ValidarNull(cidade) &&
            ValidarNull(uf) &&
            ValidarNull(_fone) &&
            ValidarNull(email) &&
            //ValidarNull(site) &&
            ValidarNull(usuario) &&
            senhaok
        ) {
            const client = {
                razao, fantasia, cpfcnpj: _cpfcnpj, endereco, numero, cep: _cep,
                bairro, cidade, uf, fone: _fone, email, site,
                usuario, senha
            }

            const res = await ApiClient.postClient(client);
            console.log(res);
            console.log(res.data);
            if (res.status === 200) {
                this.setState({
                    modalshow: true,
                    modalheader: 'Cadastro',
                    modaltitle: 'Cadastro de Cliente',
                    modalmsg: 'Cadastrado com sucesso, entraremos em contato em breve!',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.props.history.push('/'),
                });
            } else {
                this.setState({
                    modalshow: true,
                    modalheader: 'Cadastro',
                    modaltitle: res.data.msg || 'Cadastro de Cliente',
                    modalmsg:
                        'Ocorreu um pequeno problema ao te cadastrar, ' +
                        'algum dos campos deve estar incorreto ou não preenchido, ' +
                        'poderia verificar por favor?',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });
            }
        } else {
            this.setState({
                modalshow: true,
                modalheader: 'Cadastro',
                modaltitle: 'Cadastro de Cliente',
                modalmsg:
                    'Ocorreu um pequeno problema ao te cadastrar, ' +
                    'algum dos campos deve estar incorreto ou não preenchido, ' +
                    'poderia verificar por favor?',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    render() {
        const {
            razao, fantasia, cpfcnpj, endereco, numero, cep,
            bairro, cidade, uf, fone, email, site,
            usuario, senha, confirmsenha, senhaok, forcasenha, barcolor
            , modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton
        } = this.state;

        return (
            <>
                {modalshow &&
                    <Modal header={modalheader} title={modaltitle}
                        message={modalmsg}
                        textButton={modaltextbutton}
                        actionButton={modalactionbutton} />
                }
                <div className='body-register p-3'>
                    <div className='col-md-8 card-register p-3'>
                        <h2 className='title text-center'>Cadastre-se agora!</h2>
                        <br />
                        
                        <label className='label'>* campos obrigatórios</label>

                        <h3 className='title'>Dados</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Razão Social / Nome *</label>
                                <input className='form-control' value={razao} maxLength='100' onChange={e => this.setState({ razao: e.target.value })} />
                            </div>
                            <div className="form-group col-md-6">
                                <label className='label'>Fantasia / Apelido *</label>
                                <input className='form-control' value={fantasia} maxLength='100' onChange={e => this.setState({ fantasia: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-4">
                                <label className='label'>CPF / CNPJ *</label>
                                <input className='form-control' 
                                       value={cpfcnpj} 
                                       maxLength='18'
                                       onChange={e => this.setState({ cpfcnpj: mCNPJCPF(e.target.value) })} />
                            </div>
                        </div>

                        <br />
                        <h3 className='title'>Endereço</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-3">
                                <label className='label'>CEP *</label>
                                <input className='form-control' 
                                       value={cep} 
                                       maxLength='10'
                                       onChange={e => this.setState({ cep: mCEP(e.target.value) })} />
                            </div>
                            <div className="form-group col-md-5">
                                <br />
                                <button className='btn btn-info' onClick={_ => this.handleCep()}>
                                    Pesquisar
                            </button>
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-7">
                                <label className='label'>Endereço *</label>
                                <input className='form-control' value={endereco} maxLength='150' onChange={e => this.setState({ endereco: e.target.value })} />
                            </div>

                            <div className="form-group col-md-3">
                                <label className='label'>Nº *</label>
                                <input className='form-control' type='number' maxLength='6' value={numero} onChange={e => this.setState({ numero: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Bairro *</label>
                                <input className='form-control' value={bairro} maxLength='80' onChange={e => this.setState({ bairro: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Cidade *</label>
                                <input className='form-control' value={cidade} maxLength='150' onChange={e => this.setState({ cidade: e.target.value })} />
                            </div>

                            <div className="form-group col-md-2">
                                <label className='label'>UF *</label>
                                <select class="custom-select" id="uf" value={uf} onChange={e => this.setState({ uf: e.target.value })}>
                                    {
                                        ufs.map(sg =>
                                            <option value={sg}>{sg}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <br />
                        <h3 className='title'>Contato</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>Fone *</label>
                                <input className='form-control' value={fone} onChange={e => this.setState({ fone: FormatPhoneMask(e.target.value) })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label className='label'>E-mail *</label>
                                <input className='form-control' 
                                       value={email} 
                                       type="email"
                                       maxLength='100'
                                       onChange={e => this.setState({ email: e.target.value })}
                                       onBlur={e => this.handleEmail(e.target.value)}
                                />
                                       
                            </div>

                            <div className="form-group col-md-6">
                                <label className='label'>Site</label>
                                <input className='form-control' value={site} maxLength='150' onChange={e => this.setState({ site: e.target.value })} />
                            </div>
                        </div>

                        <br />
                        <h3 className='title'>Login</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-4">
                                <label className='label'>Usuário *</label>
                                <input className='form-control' value={usuario} maxLength='20' onChange={e => this.setState({ usuario: e.target.value })} />
                            </div>

                            <div className="form-group col-md-4">
                                <label className='label'>Senha *</label>&nbsp;<small className='muted-register'>(6 á 12 dígitos)</small>
                                <input className='form-control' value={senha} type='password' maxLength={12} onChange={e => this.setSenha(e.target.value)} />
                                <small className='muted-register'>Força da senha</small>
                                <div className="progress">
                                    <div className={`progress-bar ${barcolor}`} role="progressbar" style={{ width: forcasenha }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label className='label'>Confirmar senha *</label>
                                <input className='form-control' value={confirmsenha} type='password' maxLength={12} onChange={e => this.setConfirmSenha(e.target.value)} />
                                {confirmsenha.length > 0 &&
                                    <small className='muted-register'>
                                        {
                                            senhaok
                                                ? 'Confirmação OK!'
                                                : 'Senhas não correspondem!'
                                        }
                                    </small>
                                }
                            </div>
                        </div>

                        <br />

                        <div className='col-md-12 left-register'>
                            <button className='btn btn-success btn-entrar' onClick={_ => this.register()}>Cadastrar</button>
                        </div>
                    </div >
                    <br />
                </div >
            </>
        )
    }
}