import React from 'react';
import ApiOrder from '../../api/order';
import { FormatDateTime, valorStr } from '../../common/functions';
import ClientDrawer from '../cliente-drawer';
import './index.css';

export default class Orders extends React.Component {
    render() {
        return <ClientDrawer content={<Page />} />
    }
}

class Page extends React.Component {

    state = {
        orders: [],
        statusOpen: false,
        user: {},
    }

    componentDidMount = async () => {
        let user = await localStorage.getItem('user');
        user = await JSON.parse(user);
        this.setState({ user });

        const orders = await ApiOrder.getAll(user.id).then(res => res.data).catch(res => []);
        this.setState({ orders });
        console.log(orders);
    }

    render() {
        const { orders, statusOpen } = this.state;
        return (
            <div className="container m-3">
                <h1 className="text-center">Pedidos</h1>
                <hr />
                <br />
                <div className="content col-md-12">

                </div>
                <div>
                    <button className='btn btn-info' onClick={_ => this.setState({ statusOpen: !statusOpen })}>Status</button>
                    <br />
                    {statusOpen &&
                        <div>
                            [AB = Aberto]
                            [AC = Aceito]
                            [RC = Recusado]
                            [ER = Em Rota]
                            [CA = Cancelado]
                            [OK = Entregue]
                    </div>
                    }
                    <br />
                    <OrderTable orders={orders} />
                </div>
            </div>
        );
    }
}

class OrderTable extends React.Component {
    render() {
        const { orders } = this.props;
        return (
            <table class="table table-striped table-hover">
                <thead class="thead-dark">
                    <th>Id</th>
                    <th>Entregador</th>
                    <th>Data Lancto</th>
                    <th>Valor</th>
                    <th>Data Coleta</th>
                    <th>Data Entrega</th>
                    <th>Status</th>
                </thead>
                <tbody>
                    {orders.map((order, idx) => <OrderDetail key={idx} order={order} idx={idx} />)}
                </tbody>
            </table>
        )
    }
}

class OrderDetail extends React.PureComponent {
    render() {
        const { order } = this.props;
        return (
            <tr>
                <td>{order.id}</td>
                <td>{order.identregador > 0 && `${order.identregador} - ${order.fantasia}`}</td>
                <td>{order.datalancto}</td>
                <td>{`R$ ${valorStr(order.valor)}`}</td>
                <td>{order.dataentrega}</td>
                <td>{order.datacoleta}</td>
                <td>{order.status}</td>
            </tr>
        )
    }
}