import React from 'react';

import ApiMap from '../../api/map';
import ApiClient from '../../api/client';

import Modal from '../../components/modal';
import { ValidarNull, mCNPJCPF, mCEP, FormatPhoneMask, validarEmail } from '../../common/functions';
import { isUndefined } from 'lodash';
import { ufs } from '../../common/constants';
import ClientDrawer from '../cliente-drawer';

export default class Profile extends React.Component {
    render() {
        return <ClientDrawer content={<Page />} />
    }
}

class Page extends React.Component {

    state = {
        profile: {},
        user: {},

        razao: '',
        fantasia: '',
        cpfcnpj: '',
        endereco: '',
        numero: '',
        cep: '',
        bairro: '',
        cidade: '',
        uf: '',
        fone: '',
        email: '',
        site: '',

        modalshow: false,
        modalheader: '',
        modaltitle: '',
        modalmsg: '',
        modaltextbutton: '',
        actionButton: () => this.setState({ modalshow: false }),
    }

    // Cep
    handleCep = async _ => {

        const { cep } = this.state;
        if (!isUndefined(cep)) {

            const local = await ApiMap.getLocation(cep.replace('.', '').replace('-', ''));
            if (ValidarNull(local)) {
                this.setState({
                    cep: local.cep,
                    endereco: local.logradouro,
                    bairro: local.bairro,
                    cidade: local.localidade,
                    uf: local.uf,
                });
            }
            else {
                this.setState({
                    modalshow: true,
                    modalheader: 'Ops!',
                    modaltitle: 'CEP',
                    modalmsg:
                        'Não encontramos o CEP informado. Verifique e tente novamente. ',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });

                this.setState({
                    cep: '',
                    endereco: '',
                    bairro: '',
                    cidade: '',
                    uf: 'UF',
                });
            }
        }
        else {
            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'CEP',
                modalmsg:
                    'Não encontramos o CEP informado. Verifique e tente novamente. ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    // E-mail
    handleEmail(email) {

        if (!validarEmail(email)) {

            this.setState({
                email: ''
            });

            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'E-mail inválido',
                modalmsg:
                    'Digite um e-mail válido e tente novamente! ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    componentDidMount = async () => {
        let user = await localStorage.getItem('user');
        user = await JSON.parse(user);
        this.setState({ user });

        console.log(user.idref);

        const profile = await ApiClient.getById(user.idref).then(res => res[0]).catch(res => []);
        this.setState({ profile });
        console.log(profile);
    }

    saveProfile = async _ => {

        const {
            user, razao, fantasia, cpfcnpj,
            endereco, numero, cep, bairro, cidade, uf,
            fone, email, site
        } = this.state;

        // Remover máscara dos campos
        let _cpfcnpj = cpfcnpj.replace(/[^\d]+/g, '');
        let _cep = cep.replace(/[\(\)\.\s-]+/g, '');
        let _fone = fone.replace(/[\(\)\.\s-]+/g, '');

        if (
            ValidarNull(razao) &&
            ValidarNull(fantasia) &&
            ValidarNull(_cpfcnpj) &&
            ValidarNull(endereco) &&
            ValidarNull(numero) &&
            ValidarNull(_cep) &&
            ValidarNull(bairro) &&
            ValidarNull(cidade) &&
            ValidarNull(uf) &&
            ValidarNull(_fone) &&
            ValidarNull(email)
        ) {
            const client = {
                user, razao, fantasia, cpfcnpj: _cpfcnpj,
                endereco, numero, cep: _cep, bairro, cidade, uf,
                fone: _fone, email, site
            }

            // Atualiza os dados
            const res = await ApiClient.postClient(client);
            console.log(res);
            console.log(res.data);

            if (res.status === 200) {
                this.setState({
                    modalshow: true,
                    modalheader: 'Sucesso!',
                    modaltitle: 'Alteração de perfil',
                    modalmsg: 'Dados alterados com sucesso!',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.props.history.push('/'),
                });
            } else {
                this.setState({
                    modalshow: true,
                    modalheader: 'Ops!',
                    modaltitle: res.data.msg || 'Alteração de perfil',
                    modalmsg:
                        'Dados não alterados. ' +
                        'Não conseguimos atualizar seus. Por favor, tente novamente ',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });
            }
        }
        else {

            this.setState({
                modalshow: true,
                modalheader: 'Atenção!',
                modaltitle: 'Alteração de perfil',
                modalmsg:
                    'Preencha todos os campos obrigatórios para editar o perfil. Dados não alterados. ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });
        }
    }

    render() {
        const {
            profile,
            razao, fantasia, cpfcnpj, endereco, numero, cep,
            bairro, cidade, uf, fone, email, site,
            modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton
        } = this.state;

        return (
            <>
                {modalshow &&
                    <Modal header={modalheader} title={modaltitle}
                        message={modalmsg}
                        textButton={modaltextbutton}
                        actionButton={modalactionbutton} />
                }
                <div className="container m-3">
                    <h1 className="text-center">Perfil</h1>
                    <hr />
                    <br />
                    <label className='col-md'> * campos obrigatórios</label>

                    <div className="content col-md-12">
                        <h3>Dados</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label>Razão Social / Nome *</label>
                                <input className='form-control' readOnly value={profile.razao} maxLength='100' onChange={e => this.setState({ razao: e.target.value })} />
                            </div>
                            <div className="form-group col-md-6">
                                <label >Fantasia / Apelido *</label>
                                <input className='form-control' readOnly value={profile.fantasia} maxLength='100' onChange={e => this.setState({ fantasia: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-4">
                                <label >CPF / CNPJ *</label>
                                <input className='form-control'
                                    readOnly
                                    value={profile.cpfcnpj}
                                    maxLength='18'
                                    onChange={e => this.setState({ cpfcnpj: mCNPJCPF(e.target.value) })} />
                            </div>
                        </div>

                        <br />
                        <h3>Endereço</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-3">
                                <label >CEP *</label>
                                <input className='form-control'
                                    value={profile.cep}
                                    maxLength='10'
                                    onChange={e => this.setState({ cep: mCEP(e.target.value) })} />
                            </div>
                            <div className="form-group col-md-5">
                                <br />
                                <button className='btn btn-info' onClick={_ => this.handleCep()}>
                                    Pesquisar
                                </button>
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-7">
                                <label >Endereço *</label>
                                <input className='form-control' value={profile.endereco} maxLength='150' onChange={e => this.setState({ endereco: e.target.value })} />
                            </div>

                            <div className="form-group col-md-3">
                                <label >Nº *</label>
                                <input className='form-control' type='number' maxLength='6' value={profile.numero} onChange={e => this.setState({ numero: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Bairro *</label>
                                <input className='form-control' value={profile.bairro} maxLength='80' onChange={e => this.setState({ bairro: e.target.value })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Cidade *</label>
                                <input className='form-control' value={profile.cidade} maxLength='150' onChange={e => this.setState({ cidade: e.target.value })} />
                            </div>

                            <div className="form-group col-md-2">
                                <label >UF *</label>
                                <select class="custom-select" id="uf" value={profile.uf} onChange={e => this.setState({ uf: e.target.value })}>
                                    {
                                        ufs.map(sg =>
                                            <option value={sg}>{sg}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <br />
                        <h3>Contato</h3>
                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >Fone *</label>
                                <input className='form-control' value={profile.fone} onChange={e => this.setState({ fone: FormatPhoneMask(e.target.value) })} />
                            </div>
                        </div>

                        <div className='row col-md-12'>
                            <div className="form-group col-md-6">
                                <label >E-mail *</label>
                                <input className='form-control'
                                    value={profile.email}
                                    type="email"
                                    maxLength='100'
                                    onChange={e => this.setState({ email: e.target.value })}
                                    onBlur={e => this.handleEmail(e.target.value)}
                                />

                            </div>

                            <div className="form-group col-md-6">
                                <label >Site</label>
                                <input className='form-control' value={profile.site} maxLength='150' onChange={e => this.setState({ site: e.target.value })} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='col-md-6 left-register'>
                        <button className='btn btn-success btn-entrar' onClick={_ => this.saveProfile()}>Salvar Dados</button>
                    </div>
                    <br />
                </div>
            </>
        );
    }
}