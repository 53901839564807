import Api from '../index';

export const ApiDriver = {
    async postDriver(driver) {
        return await Api.Route()
            .post(`driver/create`, driver)
            .then(res => res)
            .catch(e => e);
    },
    async postFotoDriver(foto) {
        return await Api.Route()
            .post(`driver/createfoto`, foto)
            .then(res => res.data)
            .catch(e => e);
    },
}

export default ApiDriver;
