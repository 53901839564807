import React from 'react';
import './index.css';
import ClientDrawer from '../cliente-drawer';
import { meses, anos } from '../../common/constants';
import { FormatDateTime, ValidarNull, valorStr } from '../../common/functions';
import ApiClient from '../../api/client';

export default class Reports extends React.Component {
  render() {
    return <ClientDrawer content={<Page />} />
  }
}

class Page extends React.Component {
  state = {
    user: null,
    ano: '2021',
    mes: '01',
    typePeriod: 1,
    data: [],
    total: 0,
  }

  componentDidMount = async () => {
    let user = await localStorage.getItem('user');
    user = await JSON.parse(user);
    this.setState({ user });

  }

  getReport = async _ => {

    const { user, ano, mes, typePeriod } = this.state;

    const data = await ApiClient.getReport(user.idref, ano, mes, typePeriod);

    const total = ValidarNull(data) ? valorStr(data.reduce((total, item) => total + item.valor, 0)) : 0;

    this.setState({ data, total });
  }

  render() {
    const { typePeriod, data, total } = this.state;
    return (
      <div className="m-3">
        <h1 className="text-center">Relatórios</h1>
        <hr />
        <br />
        <div className='row justify-content-md-center'>
          <div className='report-period text-center col col-md-3'>
            <label for="Ano">Ano:</label>
                        &nbsp;&nbsp;&nbsp;
                        <select name="Ano" id="Ano" className='report-select' onChange={e => this.setState({ ano: e.target.value })}>
              {anos.map(ano => <option value={ano}>{ano}</option>)}
            </select>
          </div>
          <div className='report-period text-center col col-md-3'>
            <label for="Meses">Mês:</label>
                        &nbsp;&nbsp;&nbsp;
                        <select name="Meses" id="Meses" className='report-select' onChange={e => this.setState({ mes: e.target.value })}>
              {meses.map(mes => <option value={mes.id}>{mes.text}</option>)}
            </select>
          </div>
          <div className='report-period text-center col col-md-3'>
            <input type='radio' id="quinzena1" name="quinzena1"
              checked={typePeriod == 1}
              onClick={() => this.setState({ typePeriod: 1 })} />
                            &nbsp;
                        <label for="quinzena1">1ªQuinzena</label>
                        &nbsp;&nbsp;
                        <input type='radio' id="quinzena2" name="quinzena2"
              checked={typePeriod == 2}
              onClick={() => this.setState({ typePeriod: 2 })} />
                            &nbsp;
                        <label for="quinzena2">2ªQuinzena</label>
                        &nbsp;&nbsp;
                        <input type='radio' id="mensal" name="mensal"
              checked={typePeriod == 3}
              onClick={() => this.setState({ typePeriod: 3 })} />
                            &nbsp;
                        <label for="mensal">Mensal</label>
          </div>
          <div className='text-center col col-md-1'>
            <button className='btn btn-success' onClick={_ => this.getReport()}>Filtrar</button>
          </div>
        </div>
        <br />
        {data && <Table data={data} />}
        <hr />
        <h1 className="text-right mr-3">Total R${total}</h1>
      </div>
    );
  }
}

class Table extends React.PureComponent {
  render() {
    const { data } = this.props;
    return (
      <table className="table table-striped table-hover">
        <thead className="table-dark">
          <th>Id</th>
          <th>Id Entregador</th>
          <th>Data Lancto</th>
          <th>Data Coleta</th>
          <th>Data Entrega</th>
          <th>KM's</th>
          <th>Valor</th>
        </thead>
        <tbody>
          {data.map((dt, idx) => <TableContent key={idx} dt={dt} idx={idx} />)}
        </tbody>
      </table>
    );
  }
}

class TableContent extends React.PureComponent {
  render() {
    const { dt } = this.props;
    return (
      <tr>
        <td>{dt.id}</td>
        <td>{ValidarNull(dt.identregador) ? dt.identregador : 'N/D' }</td>
        <td>{FormatDateTime(dt.datalancto)}</td>
        <td>{FormatDateTime(dt.datacoleta)}</td>
        <td>{FormatDateTime(dt.dataentrega)}</td>
        <td>{dt.km.toFixed(3)}</td>
        <td>R$ {valorStr(dt.valor)}</td>
      </tr>
    );
  }
}