import React from "react";
import ApiClient from '../../api/client';
import { validarEmail, FormatPhoneMask, ValidarNull } from '../../common/functions';

export default class Contact extends React.Component {

  state = {
    nome: '',
    email: '',
    fone: '',
    empresa: '',
    mensagem: '',
  }

  handleEmail(email) {

    if (!validarEmail(email)) {

      this.setState({
        email: '',
        modalshow: true,
        modalheader: 'Ops!',
        modaltitle: 'E-mail inválido',
        modalmsg: 'Digite um e-mail válido e tente novamente! ',
        modaltextbutton: 'Ok, entendi!',
        modalactionbutton: () => this.setState({ modalshow: false }),
      });
    }
  }

  addEmail = async _ => {

    const {
      nome, email, fone, empresa, mensagem
    } = this.state;

    // Remover máscara dos campos
    const _fone = fone.replace(/[\(\)\.\s-]+/g, '');

    const data = {
      modalshow: true,
      modalheader: '',
      modaltitle: '',
      modalmsg: '',
      modaltextbutton: '',
    };

    if (
      ValidarNull(nome) &&
      ValidarNull(email) &&
      ValidarNull(fone) &&
      ValidarNull(empresa) &&
      ValidarNull(mensagem)
    ) {

      const emailAdd = {
        nome, email, fone: _fone, empresa, mensagem
      };
      const res = await ApiClient.postAddEmail(emailAdd);

      if (res.status === 200) {
        data.modalshow = true;
        data.modalheader = 'Fale conosco';
        data.modaltitle = 'Mensagem enviada com sucesso.';
        data.modalmsg = 'Obrigado por entrar em contato. Retornaremos em breve!';
        data.modaltextbutton = 'Ok, entendi!';
      }
      else {
        data.modalshow = true;
        data.modalheader = 'Ops!';
        data.modaltitle = res.data.msg || 'Mensagem não enviada';
        data.modalmsg = 'Sua mensagem não foi enviada, por favor tente novamente!';
        data.modaltextbutton = 'Ok, entendi!';
      }
    }
    else {
      data.modalshow = true;
      data.modalheader = 'Fale conosco';
      data.modaltitle = 'Mensagem não enviada';
      data.modalmsg = 'Por favor, preencha todas as informações para enviar a mensagem. ';
      data.modaltextbutton = 'Ok, entendi!';
    }

    this.props.controlModal(data);
  }

  render() {

    const {
      nome, email, fone, empresa, mensagem, modalshow,
      modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton
    } = this.state;

    return (
      <React.Fragment>
        <section id="contact" className={"contact-us ptb-100 gray-light-bg"}>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="section-heading">
                  <h3>Same Day</h3>
                  <p>Sua entrega no mesmo dia!</p>
                </div>
              </div>
              <div className="col-md-7">
                <form
                  method="POST"
                  id="contactForm1"
                  className="contact-us-form"
                >
                  <h5>Fale conosco</h5>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={nome}
                          onChange={e => this.setState({ nome: e.target.value })}
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Nome"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={email}
                          onChange={e => this.setState({ email: e.target.value })}
                          onBlur={e => this.handleEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={fone}
                          onChange={e => this.setState({ fone: FormatPhoneMask(e.target.value) })}
                          type="text"
                          name="phone"
                          className="form-control"
                          id="phone"
                          placeholder="Fone"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="form-group">
                        <input
                          value={empresa}
                          onChange={e => this.setState({ empresa: e.target.value })}
                          type="text"
                          name="company"
                          size="40"
                          className="form-control"
                          id="company"
                          placeholder="Empresa"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          value={mensagem}
                          onChange={e => this.setState({ mensagem: e.target.value })}
                          name="message"
                          id="message"
                          className="form-control"
                          rows="7"
                          cols="25"
                          placeholder="Mensagem"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <button
                        type="button"
                        className="btn solid-btn"
                        id="btnContactUs"
                        onClick={this.addEmail}
                      >
                        Enviar
                      </button>

                    </div>
                  </div>
                </form>
                <p className="form-message"></p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

