import Api from '../index';

export const ApiCredentials = {
    async authEmp(credentials) {
        return await Api.Route()
            .post('login/authEmp', credentials)
            .then(res => res)
            .catch(e => e);
    },
}

export default ApiCredentials;
