import React, { useState } from 'react';

import Header from './header';
import Landing from './landing';
import About from './about';
import Present from './present';
import Pricing from './pricing';
import Contact from './contact';
import Footer from './footer';
import Modal from '../components/modal';

const Home = () => {
    const [modalData, setModalData] = useState({
        modalshow: false,
        modalheader: '',
        modaltitle: '',
        modalmsg: '',
        modaltextbutton: ''
    });

    const fillModal = data => setModalData({
        modalshow: data.modalshow,
        modalheader: data.modalheader,
        modaltitle: data.modaltitle,
        modalmsg: data.modalmsg,
        modaltextbutton: data.modaltextbutton,
    });

    return (
        <React.Fragment>
            {
                modalData.modalshow &&
                <Modal header={modalData.modalheader} title={modalData.modaltitle}
                    message={modalData.modalmsg}
                    textButton={modalData.modaltextbutton}
                    actionButton={() => setModalData({ ...modalData, modalshow: false })} />

            }
            <Header isTransparent />
            <Landing />
            <About />
            <Present />
            <Pricing />
            <Contact controlModal={data => fillModal(data)} />
            <Footer />

        </React.Fragment>
    );
}

export default Home;