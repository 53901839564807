import React from 'react';
import { io } from 'socket.io-client';
import { Redirect } from "react-router-dom";

import './index.css';
import ApiMap from '../../api/map';
import ApiOrder from '../../api/order';
import ApiClient from '../../api/client';
import Modal from '../../components/modal';
import { ufs } from '../../common/constants';
import { ValidarNull, valorStr, valorDouble, FormatPhoneMask, mCNPJCPF, mCEP } from '../../common/functions';
import ClientDrawer from '../cliente-drawer';

export default class NewOrder extends React.Component {
    render() {
        return <ClientDrawer content={<Page />} />
    }
}

class Page extends React.Component {
    state = {
        modalshow: false,
        user: {},
        redirect: null,

        cliente_nome: null,
        cliente_cpf_cnpj: null,
        cliente_celular: null,
        cep: null,
        endereco: null,
        numero: null,
        bairro: null,
        cidade: null,
        uf: 'UF',
        peso: null,
        altura: null,
        largura: null,
        comprimento: null,
        kmText: null,
        kmValue: null,
        timeText: null,
        timeValue: null,
        valor: 0,
        infoCliente: false,
        razao_empresa: '',
        cep_empresa: null,
        latitude: null,
        longitude: null,
        empresalatitude: null,
        empresalongitude: null,
    }

    async componentDidMount() {
        this.socket = io('http://192.168.0.11:3000');
        this.socket.on('connect', function () {
            console.log('connect');
            //this.socket.emit('message', 'Hello server');
        });
        this.loadData();
    }

    async loadData() {
        let user = await localStorage.getItem('user');
        user = JSON.parse(user);
        this.setState({ user });

        const ret = await ApiClient.getById(user.idref);
        this.setState({
            cep_empresa: ret[0].cep,
            empresalatitude: ret[0].latitude,
            empresalongitude: ret[0].longitude
        });
    }

    async handleCep() {
        const { cep, cep_empresa } = this.state;
        const local = await ApiMap.getLocation(cep.replace('.', '').replace('-', ''));

        if (ValidarNull(local)) {
            const way = await ApiMap.getDistance(cep_empresa, cep.replace('.', '').replace('-', '')).then(res => res.data);
            const geo = await ApiMap.getLatLong(cep_empresa).then(res => res.data);
            if (ValidarNull(way)) {
                this.setState({
                    cep: local.cep,
                    endereco: local.logradouro,
                    bairro: local.bairro,
                    cidade: local.localidade,
                    uf: local.uf,

                    kmText: way.distance.text,
                    kmValue: way.distance.value,
                    timeText: way.duration.text,
                    timeValue: way.duration.value,
                    valor: way.distance.value / 1000,

                    latitude: geo.lat,
                    longitude: geo.lng,
                });
            }
            else {

                this.setState({
                    modalshow: true,
                    modalheader: 'Ops!',
                    modaltitle: 'CEP',
                    modalmsg:
                        'Não encontramos o CEP informado. Verifique e tente novamente. ',
                    modaltextbutton: 'Ok, entendi!',
                    modalactionbutton: () => this.setState({ modalshow: false }),
                });

                this.setState({
                    cep: '',
                    endereco: '',
                    bairro: '',
                    cidade: '',
                    uf: 'UF',
                    kmText: '',

                    kmValue: '',
                    timeText: '',
                    timeValue: '',
                    valor: 0,
                });

            }
        }
        else {

            this.setState({
                modalshow: true,
                modalheader: 'Ops!',
                modaltitle: 'CEP',
                modalmsg:
                    'Não encontramos o CEP informado. Verifique e tente novamente. ',
                modaltextbutton: 'Ok, entendi!',
                modalactionbutton: () => this.setState({ modalshow: false }),
            });

            this.setState({
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                uf: 'UF',
                kmText: '',

                kmValue: '',
                timeText: '',
                timeValue: '',
                valor: 0,
            });
        }
    }

    async handleGetprice() {
        const { cep, cep_empresa, peso, comprimento,
            altura, largura, valor } = this.state;

        const data = {
            cep_empresa,
            cep,
            peso,
            comprimento,
            altura,
            largura,
            valor
        };

        const price = await ApiMap.postPrice(data);
    }

    /* Apenas para teste - Descomentar o botão*/
    async concluirPedidoDireto() {
        await this.setState({
            cliente_nome: 'Cliente',
            cliente_cpf_cnpj: '444',
            cliente_celular: '19994451249',
            cep: '13051145',
            endereco: 'Avenida',
            numero: 100,
            bairro: 'Bairro',
            cidade: 'Campinas',
            uf: 'SP',
            peso: '11,5',
            altura: '1,0',
            largura: '1,0',
            comprimento: '1,0',
            kmText: 23,
            kmValue: 27,
            timeText: 60,
            timeValue: 60,
            valor: 100,
            infoCliente: false,
            razao_empresa: 'Empresa',
            cep_empresa: '13073300',
            latitude: -22.8837198,
            longitude: -47.0659778,
        });
        this.concluirPedido();
    }


    async concluirPedido() {

        const { user, cliente_nome, cliente_cpf_cnpj, cliente_celular, altura, largura, comprimento
            , kmValue, valor, status, peso, endereco, numero, cep
            , bairro, cidade, uf, latitude, longitude } = this.state;

        // Remover máscara dos campos
        let _cliente_cpf_cnpj = cliente_cpf_cnpj.replace(/[^\d]+/g, '');
        let _cep = cep.replace(/[\(\)\.\s-]+/g, '');
        let _cliente_celular = cliente_celular.replace(/[\(\)\.\s-]+/g, '');

        if (!ValidarNull(cliente_nome)) {
            alert('Informe o Nome ou Razão Social do Cliente.');
            return
        }

        if (!ValidarNull(_cliente_cpf_cnpj)) {
            alert('Informe o CPF ou CNPJ do Cliente.');
            return
        }

        if (!ValidarNull(_cliente_celular)) {
            alert('Informe o Celular do Cliente.');
            return
        }

        if (!ValidarNull(_cep)) {
            alert('Informe o cep.');
            return;
        }
        if (!ValidarNull(numero)) {
            alert('Informe o numero.');
            return;
        }
        if (!ValidarNull(peso)) {
            alert('Informe o peso.');
            return;
        }
        if (!ValidarNull(altura)) {
            alert('Informe o altura.');
            return;
        }
        if (!ValidarNull(largura)) {
            alert('Informe o largura.');
            return;
        }
        if (!ValidarNull(comprimento)) {
            alert('Informe o comprimento.');
            return;
        }

        if (!ValidarNull(latitude)) {
            alert('Local de entrega não encontrado.');
            return;
        }
        if (!ValidarNull(longitude)) {
            alert('Local de entrega não encontrado.');
            return;
        }


        const order = await {
            idempresa: user.idref,
            cliente_nome,
            cliente_cpf_cnpj: _cliente_cpf_cnpj,
            cliente_celular: _cliente_celular,
            altura: valorDouble(altura, 3),
            largura: valorDouble(largura, 3),
            comprimento: valorDouble(comprimento, 3),
            peso: valorDouble(peso, 3),
            km: kmValue / 1000,
            valor,
            status,
            endereco,
            numero,
            cep: _cep,
            bairro,
            cidade,
            uf,
            latitude,
            longitude
        }

        const ret = await ApiOrder.postOrder(order);
        if (ret.status == '200') {
            this.sendOrder(ret.data.id);
            this.setState({
                modalshow: true,
                modalheader: 'Sucesso!',
                modaltitle: 'Pedido enviado',
                modalmsg: 'Seu pedido foi enviado, em breve um dos nossos motoristas receberá a entrega e irá retirar o pacote.',
                modaltextbutton: 'Ok',
                modalactionbutton: '/client-dashboard'
            });
        }
    }

    handleKeyDown(e) {
        if (e.key === "Tab") {
            this.handleCep(e.target.value);
        } else {
            this.setState({ cep: e.target.value });
        }
    }

    sendOrder = id => {
        const { razao_empresa, cep_empresa, valor } = this.state;
        this.socket.emit('sendOrder', { id, razao_empresa, cep_empresa, valor });
    }

    render() {

        const {
            cliente_nome, cliente_cpf_cnpj, cliente_celular, cep,
            endereco, numero, bairro, cidade,
            uf, peso, altura, largura,
            comprimento, kmText, timeText, valor, infoCliente,
            modalshow, modalheader, modaltitle, modalmsg, modaltextbutton, modalactionbutton
        } = this.state;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <>
                {modalshow &&
                    <Modal
                        header={modalheader} title={modaltitle}
                        message={modalmsg}
                        textButton={modaltextbutton}
                        actionButton={modalactionbutton}
                        typeButton='link'
                    />
                }

                <div className="container m-3">
                    <h1 className="text-center">Novo Pedido</h1>
                    <hr />
                    <br />
                    <div className="content col-md-12">
                        {/*<!--Dados Cliente-- >*/}
                        <div className="row col-md-10">
                            <h3>Dados do Cliente</h3>
                        </div>
                        <div className="row col-md-10">
                            <div className=" form-group col-md-12">
                                <label>Nome / Razão Social</label>
                                <input type='text' className="form-control"
                                    placeholder="Nome ou Razão Social do cliente"
                                    onChange={e => this.setState({ cliente_nome: e.target.value })}
                                    value={cliente_nome} />
                            </div>
                        </div>
                        <div className="row col-md-10">
                            <div className=" form-group col-md-6">
                                <label>CPF / CNPJ</label>
                                <input type='text' className="form-control"
                                    placeholder="Somente números"
                                    maxLength={18}
                                    onChange={e => this.setState({ cliente_cpf_cnpj: mCNPJCPF(e.target.value) })}
                                    value={cliente_cpf_cnpj} />
                            </div>
                            <div className=" form-group col-md-6">
                                <label>Celular</label>
                                <input type='text' className="form-control"
                                    placeholder="Somente números, com DDD"
                                    onChange={e => this.setState({ cliente_celular: FormatPhoneMask(e.target.value) })}
                                    value={cliente_celular} />
                            </div>
                        </div>

                        {/*<!--Endereço Entrega-- >*/}
                        <div className="row col-md-10">
                            <h3>Endereço de Entrega</h3>
                        </div>
                        <hr />
                        <div className="row col-md-10 d-none">
                            <div className=" form-group col-md-4">
                                <input type='checkbox' className="chk" checked={infoCliente} onClick={() => this.setState({ infoCliente: !infoCliente })} />
                            &nbsp;Informar cliente
                        </div>
                        </div>
                        <div className="row col-md-10">
                            <div className="form-group col-md-3">
                                <label>Cep</label>
                                <input type='text' className="form-control" maxLength={10} value={cep} onChange={e => this.setState({ cep: mCEP(e.target.value) })} />
                            </div>
                            <div className="form-group col-md-5">
                                <br />
                                <button className='btn btn-info' onClick={_ => this.handleCep()}>
                                    Pesquisar
                            </button>
                            </div>
                            <div className="form-group col-md-5">
                                <label>Endereço</label>
                                <input type='text' className="form-control" value={endereco} onChange={e => this.setState({ endereco: e.target.value })} />
                            </div>
                            <div className="form-group col-md-3">
                                <label>Número</label>
                                <input type='number' className="form-control" maxLength={6} value={numero} onChange={e => this.setState({ numero: e.target.value })} />
                            </div>
                        </div>
                        <div className="row col-md-10">
                            <div className="form-group col-md-6">
                                <label>Bairro</label>
                                <input type='text' className="form-control" value={bairro} onChange={e => this.setState({ bairro: e.target.value })} />
                            </div>
                            <div className=" form-group col-md-6">
                                <label>Cidade</label>
                                <input type='text' className="form-control" value={cidade} onChange={e => this.setState({ cidade: e.target.value })} />
                            </div>
                            <div className=" form-group col-md-2">
                                <label>UF</label>
                                <br />
                                <select className="select" value={uf} onChange={e => this.setState({ uf: e.target.value })}>
                                    {ufs.map(sg => <option value={sg}>{sg}</option>)}
                                </select>
                            </div>
                        </div>
                        <br />

                        <div className="row col-md-10 ">
                            <h3>Dados Gerais</h3>
                        </div>
                        <hr />
                        <div className="row col-md-10">
                            <div className="form-group col-md-4">
                                <label>Peso (kg)</label>
                                <input type='text' className="form-control" maxLength={10} value={peso} onChange={e => this.setState({ peso: e.target.value.replace(/[^\d\s-,/]/g, "") })} />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Altura (cm)</label>
                                <input type='text' className="form-control" maxLength={10} value={altura} onChange={e => this.setState({ altura: e.target.value.replace(/[^\d\s-,/]/g, "") })} />
                            </div>
                        </div>
                        <div className="row col-md-10">
                            <div className="form-group col-md-4">
                                <label>Comprimento (cm)</label>
                                <input type='text' className="form-control" maxLength={10} value={comprimento} onChange={e => this.setState({ comprimento: e.target.value.replace(/[^\d\s-,/]/g, "") })} />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Largura (cm)</label>
                                <input type='text' className="form-control" maxLength={10} value={largura} onChange={e => this.setState({ largura: e.target.value.replace(/[^\d\s-,/]/g, "") })} />
                            </div>
                        </div>
                        <br />
                        <>
                            {/*<!-- Endereço Retirada -->
                    <div className="row col-md-10 ">
                        <h3>Endereço de retirada</h3>
                    </div>
                    <hr />
                    <div className="row col-md-10">
                        <input type="checkbox" className="chk" />Usar endereço principal
                    </div>
                    <div className="row col-md-10">
                        <div className=" form-group col-md-2">
                            <label>Id Endereço</label>
                            <input id="txtIdEndRet" className="form-control"/>
                        </div>
                        <div className=" form-group col-md-4">
                            <label>Cep</label>
                            <input id="txtCepRet" className="form-control"/>
                        </div>
                    </div>
                    <div className="row col-md-10">
                        <div className=" form-group col-md-8">
                            <label>Logradouro</label>
                            <input id="txtLogRet" className="form-control"/>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Nº</label>
                            <input type='text' id="txtNumRet" className="form-control"/>
                        </div>
                    </div>
                    <div className="row col-md-10">
                        <div className="form-group col-md-4">
                            <label>Bairro</label>
                            <input id="txtBairroRet" className="form-control"/>
                        </div>
                        <div className=" form-group col-md-4">
                            <label>Cidade</label>
                            <input id="txtCidRet" className="form-control"/>
                        </div>
                        <div className=" form-group col-md-2">
                            <label>UF</label>
                        </div>
                    </div>
                    <br />
                */}
                        </>
                        {/*<!--Obs -->
                    <div className="row col-md-10 ">
                        <h3>Observações</h3>
                    </div>
                    <hr />
                    <div className="row col-md-10">
                        <div className=" form-group col-md-8">
                            <label>Observação na entrega</label>
                            <input id="txtDescricao" className="form-control" />
                        </div>
                    </div>
                    */}
                        {/*<!--Cálcular valores-- >*/}
                        <div className="row col-md-12">
                            <div className="form-group col-md-8">
                                <h3>Distância: {kmText}</h3>
                                <br />
                                <h3>Tempo médio: {timeText}</h3>
                            </div>
                            <div className="form-group col-md-4">
                                <h2>R$ {valorStr(valor)}</h2>
                                <br />
                                <button className="btn btn-success btn-accept" onClick={() => this.concluirPedido()}>Concluir Pedido</button>
                                {/* Apenas teste - Descomentar a função ConcluirPedidoDireto*/}
                                <button className="btn btn-success btn-accept" onClick={() => this.concluirPedidoDireto()}>Concluir Pedido Direto</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}