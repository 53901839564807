export function TratarStr(campo) {
    campo = campo.replace("/", "");
    campo = campo.replace("-", "");
    campo = campo.replace(".", "");
    campo = campo.replace("(", "");
    campo = campo.replace(")", "");
    campo = campo.replace(":", "");
    campo = campo.replace(",", "");
    campo = campo.replace(".", "");
    campo = campo.replace(";", "");
    campo = campo.replace("$", "");
    return campo;
}

/*Máscara CNPJ e CPF */
export function mCNPJCPF(cnpjCpf) {
    if (cnpjCpf.length > 14) {

        cnpjCpf = cnpjCpf.replace(/\D/g, "")
        cnpjCpf = cnpjCpf.replace(/^(\d{2})(\d)/, "$1.$2")
        cnpjCpf = cnpjCpf.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        cnpjCpf = cnpjCpf.replace(/\.(\d{3})(\d)/, ".$1/$2")
        cnpjCpf = cnpjCpf.replace(/(\d{4})(\d)/, "$1-$2")
        return cnpjCpf
    }
    else {

        cnpjCpf = cnpjCpf.replace(/\D/g, "")
        cnpjCpf = cnpjCpf.replace(/(\d{3})(\d)/, "$1.$2")
        cnpjCpf = cnpjCpf.replace(/(\d{3})(\d)/, "$1.$2")
        cnpjCpf = cnpjCpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return cnpjCpf
    }
}

/* Máscara CEP */
export function mCEP(cep) {

    cep = cep.replace(/\D/g, "")
    cep = cep.replace(/^(\d{2})(\d)/, "$1.$2")
    cep = cep.replace(/\.(\d{3})(\d)/, ".$1-$2")
    return cep
}

/* Validar E-mail */
export function validarEmail(email) {

    var emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    return emailPattern.test(email);
}

/*Máscara numérica*/
export function somenteNumeros(valor) {
    return valor.replace(/([^\d])+/gim, '');
}

export function valorStr(valor = 0, casas = 2) {
    return parseFloat(valor).toFixed(casas).replace(".", ",");
}

export function valorDouble(valor, casas = 2) {
    return parseFloat(valor.replace(",", ".")).toFixed(casas);
}

export function ValidarNull(str) {
    let ok = false;
    try {
        if (
            str !== undefined &&
            str !== null &&
            str !== "" &&
            str !== {} &&
            str !== []
        ) {
            ok = true;
        } else {
            ok = false;
        }
    } catch (ex) {
        ok = false;
    }
    return ok;
}

// dd/MM/yyyy 
export function GetDate() {
    const d = new Date();
    const data =
        ('0' + d.getDate()).slice(-2) + '/'
        + ('0' + (d.getMonth() + 1)).slice(-2) + '/'
        + d.getFullYear();
    return data;
}

// dd/MM/yyyy hh:mm
export function GetDateAndTime() {
    const d = new Date();
    const data =
        ('0' + d.getDate()).slice(-2) + '/'
        + ('0' + (d.getMonth() + 1)).slice(-2) + '/'
        + d.getFullYear()
        + " " + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2);
    return data;
}

// dd/MM/yyyy hh:mm
export function FormatDateTime(datetime) {
    const d = new Date(datetime);
    console.log(d);
    const data =
        ('0' + d.getDate()).slice(-2) + '/'
        + ('0' + (d.getMonth() + 1)).slice(-2) + '/'
        + d.getFullYear()
        + " " + ('0' + (d.getHours() + 3)).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2);
    return datetime === "0000-00-00 00:00:00" ? '--/--/----' : data;
}

// hh:mm
export function GetTime() {
    const d = new Date();
    const data = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2);
    return data;
}


export function FormatPhoneMask(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
        // 11+ digits. Format as 5+4.
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    }
    else if (r.length > 5) {
        // 6..10 digits. Format as 4+4
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    }
    else if (r.length > 2) {
        // 3..5 digits. Add (0XX..)
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    }
    else {
        // 0..2 digits. Just add (0XX
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

export function RemoveAccent(text) {
    text = text.toUpperCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'A');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'E');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'I');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'O');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'U');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'C');
    return text;
};

export function isSomenteNumeros(e) {
    let key = e.keyCode;
    if (key > 47 && key < 58) return true;
    else {
        if (key == 8 || key == 0) return true;
        else return false;
    }
}
